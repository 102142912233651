export const SaveDataToLocalStorage = async (data) => {
    let local = JSON.parse(localStorage.getItem('cart'))
    let objLocal = local?.findIndex(item => JSON.stringify({ id: data.id, carSize: data.carSize, price: data.price, properties: data.properties }) === JSON.stringify({ id: item.id, carSize: item.carSize, price: item.price, properties: item.properties }))
    if (objLocal > -1) {
        local[objLocal].quantity = local[objLocal]?.quantity + data.quantity;
        local[objLocal].totalPrice = local[objLocal]?.totalPrice + data.totalPrice;

        localStorage.setItem('cart', JSON.stringify(local));
    }
    else {
        var a = [];
        // Parse the serialized data back into an aray of objects
        a = JSON.parse(localStorage.getItem('cart')) || [];
        // Push the new data (whether it be an object or anything else) onto the array
        a.push(data);
        // Alert the array value
        // Re-serialize the array back into a string and store it in localStorage
        localStorage.setItem('cart', JSON.stringify(a));
    }
}

export const totalPriceInCard = ()=>{
    let local = JSON.parse(localStorage.getItem('cart'))
    let totalPriceArray = local?.map((item)=>item.totalPrice)
    return totalPriceArray.reduce((partialSum, a) => partialSum + a, 0);
}
export const removeItemFromCart= (data)=>{
    let local = JSON.parse(localStorage.getItem('cart'))
    let objLocal = local?.findIndex(item => JSON.stringify({ id: data.id, carSize: data.carSize, price: data.price, properties: data.properties }) === JSON.stringify({ id: item.id, carSize: item.carSize, price: item.price, properties: item.properties }))
    if (objLocal > -1) {
        local?.splice(objLocal,1)
        localStorage.setItem('cart', JSON.stringify(local));
    }
}