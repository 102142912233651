import { Flex, Image } from '@chakra-ui/react';
import logo from '../../assets/images/logo.png';
import '../../assets/styles/loader.css';
import spinner from '../../assets/vectors/spinner.svg';
function Spinner() {
    return (
        <Flex w='100%' h='100%' justifyContent={'center'} alignItems={'center'} position={'relative'}>
            <Image src={logo} position={'absolute'} transform={'translate(-50%,-50%)'} top={'50%'} left={'50%'} w={'30px'} alt="" />
            <Image src={spinner} w={'200px'} alt="" />
        </Flex>
    )
}
export default Spinner;