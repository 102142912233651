import { Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Link, Select, Stack, Text, useToast } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import map from '../assets/images/map.png';
import closeSquare from '../assets/vectors/close_square.svg';
import mapPin from '../assets/vectors/map-pin.svg';
import phone from '../assets/vectors/phone.svg';
import cartIcon from '../assets/vectors/shopping-cart.svg';
import CartItem from '../components/cart/CartItem';
import ResponsiveHeader from '../components/common/ResponsiveHeader';
import ResponsiveContext from '../context/ResponsiveContext';

import { useTranslation } from 'react-i18next';
import '../assets/styles/cart.scss';
import LocaleContext from '../context/LocaleContext';
import "../assets/styles/contactus.css";
import { useForm } from 'react-hook-form';
import emptyCart from '../assets/vectors/empty-cart.svg';
import { COUPON } from '../services/coupon';
import { CHECKOUT } from '../services/checkout';
import { detectMob, maxLengthCheck } from '../util/formHandling';
import { lazy } from 'react';

const Checkout = lazy(() => import('../components/cart/Checkout.jsx'));

const Cart = () => {
    const navigate = useNavigate()
    const { maxwidth650 } = useContext(ResponsiveContext);
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()
    const [totalPrice, setTotalPrice] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [cart, setCart] = useState([])
    const [isRemoved, setIsRemoved] = useState(false)
    const [isTabbyOpen, setIsTabbyOpen] = useState(false)
    const [isLabelVisible, setIsLabelVisible] = useState(true);
    const [checkoutData, setCheckoutData] = useState();
    const [hasRender, setRender] = useState(false);
    const [tabbyErrorMessage, setTabbyErrorMessage] = useState();
    const [searchParams, setSearchParams] = useSearchParams();

    const onShow = useCallback(() => setRender(true), []);
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, } = useForm({ defaultValues: { method: 'TABBY', buyer: { name: '', email: '', phone: '', car: '' }, reservation: { visitDay: '' }, couponCode: '', shipping: { address: "", city: "riyadh", zip: "12211" }, isValid: false, discount: 0, agreement: false }, });
    let visitDayLabel = document.getElementById('label-visit-day')

    const toast = useToast()

    const showSuccessToast = (message) => {
        toast({
            title: message,
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }
    const showErrorToast = (error) => {
        toast({
            title: locale === 'en' ? "something went wrong" : 'حدث خطأ ما',
            description: error,
            status: 'error',
            duration: 5000,
            isClosable: false,
        })
    }

    useEffect(() => {
        if (!searchParams.get('payment_id')) return;
        CHECKOUT.getCheckoutDetails(searchParams.get('payment_id'), locale)
            .catch((err) => showErrorToast(err.response.data.metaData.description))
    }, [searchParams.get('payment_id')])

    useEffect(() => {
        setCart(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : '[]')
        setIsRemoved(false);
    }, [isRemoved])

    const checkCouponClick = () => {
        setIsLoading(true)
        COUPON.checkCoupon(getValues('couponCode')).then((res) => {
            showSuccessToast(locale === 'en' ? "Coupon have been activated" : 'تم تفعيل الكوبون بنجاح',);
            let discountPrice = (res.data.discount / 100) * totalPrice
            setTotalPrice(totalPrice - discountPrice)
            setValue('discount', (res.data.discount / 100) * totalPrice)
            setValue('isValid', true)
        }).catch((err) => { showErrorToast(err.response.data.message) }).finally(() => setIsLoading(false))
    }

    const submit = async (data) => {
        onShow()
        delete data.discount
        delete data.isValid
        delete data.agreement
        if (getValues('couponCode') === '') delete data.couponCode
        let items = JSON.parse(localStorage.getItem('cart')).map((item) => ({
            carType: item.carSize,
            productId: item.id,
            subVariants: item?.properties?.map((prop) => prop.id),
            quantity: item.quantity,
        }))
        let cart = { ...data, items: items }
        setIsLoading(true)
        try {
            const res = await CHECKOUT.checkout(cart, locale)
            // window.location.href = res.data.checkoutUrl
            setCheckoutData(res.data)
            setIsTabbyOpen(true)
            setTabbyErrorMessage('')
        }
        catch (err) {
            if (err.response.data.metaData?.status === 'rejected') {
                setTabbyErrorMessage(err.response.data.metaData.description)
                setIsTabbyOpen(true)
                setCheckoutData('');
            }
            else {
                showErrorToast(err.response.data.metaData.message ? err.response.data.metaData.message : err.response.data.metaData.description)
            }
        }
        finally {
            setIsLoading(false)
        }
    }
    document.title = 'Go Pro - Cart'
    return (
        <>

            <Box overflow={'hidden'} position={'relative'}>
                {!maxwidth650 && <Box className='redPoint' bg='#FF5454' top={'40%'} left={'20%'} transform={'translate(-50%,-50%)'} zIndex={-2} borderRadius={'50%'} w='500px' h={'500px'} opacity={'0.6'} filter={'blur(100px)'} position={'absolute'}></Box>}
                {!maxwidth650 && <Box className='whitePoint' bg='white' top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} zIndex={-1} borderRadius={'50%'} w='500px' h={'500px'} opacity={'0.4'} filter={'blur(100px)'} position={'absolute'}></Box>}

                {maxwidth650 && <ResponsiveHeader backRoute={'/'} />}

                <Flex position={'relative'} w='100%' h='6rem' paddingInline={['10px', '50px', '50px', '80px', '100px']} paddingBlock={'1rem'} justifyContent={!maxwidth650 ? 'space-between' : 'center'} alignItems={'center'}>
                    {!maxwidth650 && <Flex w='50px'></Flex>}

                    <Flex mt={maxwidth650 ? '200px' : '0px'} gap={'20px'} justifyContent={'center'} alignItems={'center'}>
                        <Image w={['25px', '25px', '30px', '36px', '40px']} src={cartIcon} alt="" />
                        <Heading color={'white'} fontWeight={'medium'} letterSpacing={'0.1rem'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={['25px', '25px', '30px', '36px', '40px']}>{t('cart.title')}</Heading>
                    </Flex>

                    {!maxwidth650 && <Flex cursor={'pointer'} onClick={() => navigate('/')} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                        <Image w='15px' src={closeSquare} alt="" />
                        <Text color={'white'} fontWeight={'medium'} fontSize={'14px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'}>{t('closeButton')}</Text>
                    </Flex>}
                </Flex>
                {cart.length === 0 || cart === '[]' ?
                    <Flex h={'100vh'} justifyContent={'center'} alignItems={'center'} gap={'20px'} w={'100%'} flexDirection={'column'}>
                        <Image src={emptyCart} alt='' />
                        <Text fontFamily={locale === 'en' ? 'Oswald' : 'Cairo'} fontSize={'20px'} color={'white'}>{t('emptyCart')}</Text>
                    </Flex>
                    : (
                        <>
                            <Box w='100%' paddingInline={['10px', '20px', '50px', '50px', '100px']}>
                                <Flex position={'relative'} mb={'100px'} gap={['50px', '50px', '130px', '130px', '130px']} mt={maxwidth650 ? '120px' : '100px'} flexDirection={'column'}>
                                    {getValues('isValid') && <Box position={'absolute'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} w={'100%'} h={'100%'} cursor={'not-allowed'} opacity={'0.5'} zIndex={100000} />}
                                    {cart?.map((item, index) => (
                                        <CartItem key={index} isRemoved={isRemoved} setIsRemoved={setIsRemoved} setTotalPrice={setTotalPrice} item={item} maxwidth650={maxwidth650} />
                                    ))}
                                </Flex>

                                <Stack spacing={'40px'}>
                                    <Flex flexDirection={maxwidth650 ? 'column' : 'row'} justifyContent={'space-between'} gap={maxwidth650 ? '30px' : '0px'} >
                                        <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} justifyContent={'space-between'} gap={'20px'} alignItems={'start'}>
                                            <Text color={'white'} fontWeight={'bold'} fontSize={'18px'}>{t('cart.coupon.title')}</Text>
                                            <FormControl isInvalid={errors.couponCode}>
                                                <Input minW={maxwidth650 ? '150px' : '200px'} color={'white'} {...register('couponCode')} w='40%' type='text' />
                                                <FormErrorMessage color={'primary'}>{errors.couponCode && errors.couponCode.message?.toString()}</FormErrorMessage>
                                            </FormControl>

                                            <Button
                                                onClick={getValues('isValid') === true ? (e) => { e.preventDefault(); setTotalPrice((totalPrice + getValues('discount'))); reset({ couponCode: '' }) } : checkCouponClick}
                                                w={'150px'}
                                                type='submit'
                                                dir='rtl'
                                                isLoading={isLoading}
                                                loadingText={t('cart.coupon.activate')}
                                                paddingInline={'1.5rem'} border={'1px solid #FF5454'} color={'white'} bg='transparent' _hover={{ bg: '#FF5454' }}>
                                                {getValues('isValid') === true ? (locale === 'en' ? 'Cancel' : 'الغاء') : t('cart.coupon.activate')}
                                            </Button>
                                        </Flex>
                                        <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} w={maxwidth650 ? '100%' : 'auto'} justifyContent={maxwidth650 ? 'space-between' : 'center'} alignItems={'center'}>
                                            <Flex flexDirection={maxwidth650 ? 'column' : 'row'} justifyContent={'start'} alignItems={maxwidth650 ? 'start' : 'center'} gap={!maxwidth650 ? '20px' : '5px'} >
                                                <Text color={'white'} fontSize={['10px', '12px', '15px', '15px', '15px']} fontWeight={'normal'}>{t('cart.total')}</Text>
                                                <Text color={'white'} fontSize={'17px'} fontWeight={'bold'}>{totalPrice} {t('money')}</Text>
                                            </Flex>

                                            {maxwidth650 && <Link w={'fit-content'} href='#checkout'><Button maxW='120px' paddingBlock={['1rem', '1rem', '1rem', '1.2rem', '1.2rem']} _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontWeight={'semibold'} fontSize={['14px', '14px', '14px', '15px', '15px']}>{t('cart.payButton')}</Button></Link>}
                                        </Flex>
                                    </Flex>
                                    {!maxwidth650 && <Link w='fit-content' href='#checkout'><Button maxW='120px' paddingBlock={['1rem', '1rem', '1rem', '1.2rem', '1.2rem']} _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontWeight={'semibold'} fontSize={['14px', '14px', '14px', '15px', '15px']}>{t('cart.payButton')}</Button></Link>}
                                </Stack>
                            </Box >

                            <Box id='checkout' mt={['50px', '50px', '80px', '100px', '100px']} >
                                <Box paddingInline={['1rem', '2rem', '4rem', '5rem', '7rem']} paddingBlock={'3rem'} w='100%' h='fit-content'>

                                    <Heading color={'white'} fontSize={['35px', '35px', '35px', '40px', '40px']} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontWeight={'semibold'} mb={'20px'}>{t('cart.payFormTitle')}</Heading>
                                    <Flex flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}>
                                        <Box flex={['100%', '100%', '50%', '50%', '50%']}>
                                            <form onSubmit={handleSubmit(submit)}>
                                                <Stack className='form-container' spacing={'10'} color={'white'}>
                                                    <FormControl isInvalid={errors?.buyer?.name} maxW={['100%', '100%', '80%', '80&', '80%']}>
                                                        <Input {...register('buyer.name', { required: t('contactus.validation.name'), })} placeholder={t('contactus.form.name')} borderRadius={'0px'} outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: 'white' }} fontSize={'15px'} bg='transparent' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'1px solid white'} fontWeight='300' type='text' />
                                                        <FormErrorMessage>
                                                            {errors?.buyer?.name && errors.buyer?.name?.message?.toString()}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors?.buyer?.email} maxW={['100%', '100%', '80%', '80&', '80%']}>
                                                        <Input {...register('buyer.email', { required: t('contactus.validation.email'), })} placeholder={t('contactus.form.email')} borderRadius={'0px'} outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: 'white' }} fontSize={'15px'} bg='transparent' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'1px solid white'} fontWeight='300' type='email' />
                                                        <FormErrorMessage>
                                                            {errors?.buyer?.email && errors?.buyer?.email?.message?.toString()}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors?.buyer?.phone} maxW={['100%', '100%', '80%', '80&', '80%']}>
                                                        <InputGroup>
                                                            {locale === 'ar' &&
                                                                <InputLeftElement pointerEvents='none'>
                                                                    966+
                                                                </InputLeftElement>
                                                            }
                                                            <Input paddingStart={locale === 'ar' ? '20px' : '50px'} onInput={maxLengthCheck} maxLength={9} placeholder={t("contactus.form.phoneNumber")} borderRadius={"0px"} outline={"none"} boxShadow={"none !important"} _focusVisible={{ borderColor: "white" }} fontSize={"15px"} bg="transparent" borderLeft={"none"} borderTop="none" borderRight={"none"} borderBottom={"1px solid white"} fontWeight="300" type="number"
                                                                {...register("buyer.phone", { required: t('contactus.validation.phoneNumber'), })} />
                                                            {locale === 'en' &&
                                                                <InputLeftElement pointerEvents='none'>
                                                                    +966
                                                                </InputLeftElement>}
                                                        </InputGroup>
                                                        <FormErrorMessage>
                                                            {errors?.buyer?.phone && errors?.buyer?.phone?.message?.toString()}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors?.buyer?.car} maxW={['100%', '100%', '80%', '80&', '80%']}>
                                                        <Input {...register('buyer.car', { required: t('contactus.validation.carType&Model'), })} placeholder={t('contactus.form.carType&Model')} borderRadius={'0px'} outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: 'white' }} fontSize={'15px'} bg='transparent' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'1px solid white'} fontWeight='300' type='text' />
                                                        <FormErrorMessage>
                                                            {errors?.buyer?.car && errors?.buyer?.car?.message?.toString()}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl
                                                        position={'relative'}
                                                        maxW={["100%", "100%", "80%", "80%", "80%"]}
                                                        isInvalid={errors?.reservation?.visitDay}
                                                    >
                                                        <FormLabel id='label-visit-day' color={'#ADADAD'} fontWeight={'300'} position={'absolute'} bg={'transparent'} top={'6px'} left={!detectMob() ? (locale === 'en' ? '15px' : '') : '15px'} right={!detectMob() ? (locale === 'ar' ? '50px' : '') : '15px'}>
                                                            {t("contactus.form.bestVisitDay")}
                                                        </FormLabel>
                                                        <Input

                                                            onFocus={visitDayLabel ? () => { visitDayLabel.style.display = 'none'; setIsLabelVisible(false) } : () => { visitDayLabel.style.display = 'block'; setIsLabelVisible(true) }}
                                                            id=''
                                                            borderRadius={"0px"}
                                                            outline={"none"}
                                                            boxShadow={"none !important"}
                                                            _focusVisible={{ borderColor: "white" }}
                                                            fontSize={"15px"}
                                                            bg="transparent"
                                                            borderLeft={"none"}
                                                            borderTop="none"
                                                            borderRight={"none"}
                                                            borderBottom={"1px solid white"}
                                                            fontWeight="300"
                                                            type="date"
                                                            min={new Date().toISOString().split('T')[0]}
                                                            color={isLabelVisible ? 'transparent' : 'white'}
                                                            {...register("reservation.visitDay", {
                                                                required: t('contactus.validation.bestVisitDay'),
                                                            })}
                                                        />
                                                        <FormErrorMessage>
                                                            {errors?.reservation?.visitDay && errors?.reservation?.visitDay?.message?.toString()}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl
                                                        maxW={["100%", "100%", "80%", "80%", "80%"]}
                                                        isInvalid={errors?.shipping?.address}
                                                    >
                                                        <Input
                                                            placeholder={t("contactus.form.address")}
                                                            borderRadius={"0px"}
                                                            outline={"none"}
                                                            boxShadow={"none !important"}
                                                            _focusVisible={{ borderColor: "white" }}
                                                            fontSize={"15px"}
                                                            bg="transparent"
                                                            borderLeft={"none"}
                                                            borderTop="none"
                                                            borderRight={"none"}
                                                            borderBottom={"1px solid white"}
                                                            fontWeight="300"
                                                            type="text"
                                                            {...register("shipping.address", {
                                                                required: t('contactus.validation.address'),
                                                            })}
                                                        />
                                                        <FormErrorMessage>
                                                            {errors?.shipping?.address && errors?.shipping?.address?.message?.toString()}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={errors.agreement} fontSize={['12px', '12px', '15px', '17px', '17px']}>
                                                        <Checkbox {...register('agreement', { required: true })} colorScheme='primary' size={'sm'}>{t('cart.agree')}<span onClick={() => navigate('/privacy-policy')} style={{ textDecoration: 'underline' }}> {t('footer.privacyPolicy')}</span></Checkbox>
                                                    </FormControl>


                                                    <Button isLoading={isLoading} type='submit' maxW='180px' mb='20px' paddingBlock={['1rem', '1.2rem', '1.5rem', '1.5rem', '1.5rem']} _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontWeight={'bold'} fontSize={['15px', '15px', '17px', '17px', '17px']}>{t('cart.submit')}</Button>

                                                </Stack>
                                            </form>

                                        </Box>

                                        <Flex mt={maxwidth650 ? '20px' : '0px'} flexDirection={['row', 'row', 'column', 'column', 'column']} flex={['100%', '100%', '50%', '50%', '50%']} justifyContent={'start'} alignItems={'center'} gap={'40px'}>
                                            {!maxwidth650 && <Image src={map} minW={'150px'} maxW={['20%', '30%', '100%', '100%', '100%']} alt='' />}
                                            <Box>
                                                <Flex mb={'15px'} alignItems={'center'} gap={'10px'}>
                                                    <Image src={mapPin} alt='' />
                                                    <Heading color={'white'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontWeight={['normal', 'medium', 'semibold', 'bold', 'bold']} fontSize={'14px'}>{t('contactus.location')}</Heading>
                                                </Flex>

                                                <Flex alignItems={'center'} gap={'10px'}>
                                                    <Image src={phone} alt='' />
                                                    <Heading dir='ltr' color={'white'} fontWeight={['normal', 'medium', 'semibold', 'bold', 'bold']} fontSize={'14px'}>{t('contactus.mobile')}</Heading>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Box>
                        </>
                    )}
            </Box>
            {hasRender && <Checkout isOpen={isTabbyOpen} onClose={() => setIsTabbyOpen(false)} data={checkoutData} errorMessage={tabbyErrorMessage} totalPrice={totalPrice} />}
        </>
    )
}
export default Cart