import { Box, Button, Flex, FormControl, FormLabel, Image, Input, Stack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import smallCar from '../../assets/images/small-car.png';
import '../../assets/styles/productCard.css';
import bigCar from '../../assets/vectors/big-car.svg';
import loader from '../../assets/vectors/loader-form.svg';
import LocaleContext from "../../context/LocaleContext";
import ResponsiveContext from "../../context/ResponsiveContext";
import { SaveDataToLocalStorage } from "../../util/saveCartToLocalStorage";
import Spinner from "../common/Spinner";
const ProductCard = ({ id, image, variants, enName, arName, smallCarPrice, bigCarPrice, arDescription, enDescription }) => {
    const { maxwidth650 } = useContext(ResponsiveContext);
    const { t } = useTranslation()
    const { locale } = useContext(LocaleContext)
    const [product, setProduct] = useState({ id: id, enName: enName, arName: arName, arDescription: arDescription, enDescription: enDescription, quantity: 1, carSize: 'small', price: smallCarPrice, properties: variants?.map((variant) => ({ id: variant.subvariants[0].id, name: variant.enName, value: variant.subvariants[0].value, pricePlus: variant.subvariants[0].pricePlusOnSmallCar, type: variant.type })), totalPrice: 0 })
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState(t('addToCartButton'));


    useEffect(() => {
        setProduct({
            id: id,
            enName: enName,
            arName: arName,
            arDescription: arDescription,
            enDescription: enDescription,
            quantity: product.quantity ? product.quantity : 1,
            carSize: product?.carSize,
            price: product?.carSize === 'small' ? smallCarPrice : bigCarPrice,
            properties: variants?.map((variant) => ({
                id: variant.subvariants[0].id,
                name: variant.enName,
                value: variant.subvariants[0].value,
                pricePlus: product?.carSize === 'small' ? variant.subvariants[0].pricePlusOnSmallCar : variant.subvariants[0].pricePlusOnBigCar,
                type: variant.type
            })),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.carSize, variants])

    useEffect(() => {
        setProduct({
            ...product,
            totalPrice: ((product?.properties?.map((prop) => (prop.pricePlus)).reduce((partialSum, a) => partialSum + a, 0)) + (product?.carSize === 'small' ? smallCarPrice : bigCarPrice)) * product?.quantity
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.totalPrice])

    return (
        <Flex h={'100%'} minH='100vh' mb='30px' position={'relative'} w={'100%'} dir={locale === 'ar' ? 'ltr' : "rtl"} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
            {!maxwidth650 && <Box bg='#FF5454' top={'20%'} left={'0%'} transform={'translate(-50%,-50%)'} zIndex={0} borderRadius={'50%'} w='400px' h={'500px'} opacity={'1'} filter={'blur(100px)'} position={'absolute'} />}


            <Flex position={'relative'} justifyContent={'center'} alignItems={'center'} w='100%' maxW={'100%'} marginInline={'auto'} minW={'50%'} flex={['100%', '100%', '60%', '60%', '60%']}>
                <Box > <Image padding={['10px','10px','20px','50px','50px']} maxW={'700px'} maxH='700px' h={'100%'} position={'relative'} zIndex={'-1'} fallback={<Spinner />} src={image} w={'100%'} alt="" /></Box>
            </Flex>

            <Box overflowX={'auto'} display={'flex'} justifyContent={['start','center','center','center','center']} position={'relative'} dir={locale === 'en' ? 'ltr' : "rtl"} backgroundSize={'contain'} flex={['100%', '100%', '40%', '40%', '40%']} minW={'30%'} w='100%' maxW={'100%'}>
                <Stack paddingInline={'10px'}>

                    <Text marginInline={'20px'} textAlign={'center'} marginBottom={'50px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={['10px', '13px', '15px', '20px', '20px']} paddingInline={'10px'} color={'white'}>{locale === 'en' ? product?.enDescription : product?.arDescription}</Text>
                    <Flex justifyContent={'start'} alignItems={'center'} gap={'45px'}>
                        <Text color={'white'} fontFamily={locale === 'ar' ? 'Cairo' : 'Oswald'} fontSize={'14px'}>{locale === 'en' ? 'Car Type' : 'نوع السيارة'}</Text>
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <FormControl>
                                <FormLabel borderRadius={'5px'} paddingBlock={'20px'} paddingInline={'10px'} className="custom-product-detail-checkbox" >
                                    <Input name={'car'} defaultChecked onChange={() => setProduct({ ...product, price: smallCarPrice, carSize: 'small' })} value={'small'} type="radio" />
                                    <span style={{ fontSize: maxwidth650 ? '12px' : '16px', textAlign: 'center', fontWeight: "400", border: product.carSize === 'small' ? '2px solid white' : '' }}>
                                        <Image w='100%' src={smallCar} alt='' />
                                    </span>
                                </FormLabel>
                            </FormControl>

                            <FormControl>
                                <FormLabel borderRadius={'5px'} paddingBlock={'20px'} className="custom-product-detail-checkbox" >
                                    <Input name={'car'} onChange={() => setProduct({ ...product, price: bigCarPrice, carSize: 'big' })} value={'big'} type="radio" />
                                    <span style={{ fontSize: maxwidth650 ? '12px' : '16px', textAlign: 'center', fontWeight: "400" }}>
                                        <Image w='100%' src={bigCar} alt='' />
                                    </span>
                                </FormLabel>
                            </FormControl>
                        </Flex>
                    </Flex>
                    <Box key={product?.carSize}>
                        {variants?.map((variant, varIndex) => (
                            <Flex key={varIndex} justifyContent={'start'} alignItems={'center'} >
                                <Text w={'100px'} color={'white'} fontFamily={locale === 'ar' ? 'Cairo' : 'Oswald'} fontSize={'14px'}>{locale === 'en' ? variant?.enName : variant?.arName}</Text>
                                <Flex overflow={'auto'} maxW={'250px'} >
                                    {variant?.subvariants?.map((subvariant, subIndex) => (
                                        <FormControl key={subIndex}>
                                            <FormLabel borderRadius={'5px'} paddingBlock={'20px'} paddingInline={'10px'} className="custom-product-detail-checkbox" >
                                                <Input type="radio" defaultChecked={subIndex === 0 ? true : false} name={variant.enName} value={subvariant?.value}
                                                    onChange={
                                                        () => setProduct({
                                                            ...product,
                                                            totalPrice: ((product?.carSize === 'small' ? subvariant?.pricePlusOnSmallCar : subvariant?.pricePlusOnBigCar) + (product?.carSize === 'small' ? smallCarPrice : bigCarPrice)) * product?.quantity,
                                                            properties: product?.properties?.map((varia) => (
                                                                    {
                                                                        id: subvariant?.id,
                                                                        name: variant?.enName,
                                                                        value: subvariant?.value,
                                                                        pricePlus: product?.carSize === 'small' ? subvariant?.pricePlusOnSmallCar : subvariant?.pricePlusOnBigCar,
                                                                        type: variant?.type,
                                                                        totalPrice: (product?.carSize === 'small' ? subvariant?.pricePlusOnSmallCar : subvariant?.pricePlusOnBigCar) + (product?.carSize === 'small' ? smallCarPrice : bigCarPrice) * product?.quantity
                                                                    }
                                                            )),
                                                        })} />
                                                <span style={{ width: variant.type === 'color' ? '65px' : '', background: variant.type === 'color' ? subvariant?.value : '', fontSize: maxwidth650 ? '12px' : '16px', textAlign: 'center', fontWeight: "400" }}>
                                                    {variant?.type === 'image' ? <Image w='100%' src={`${process.env.REACT_APP_BASE_URL}/${subvariant?.value}`} alt='' />
                                                        :
                                                        variant?.type === 'text' ? <Text color='white' fontSize={'10px'} fontWeight={'Oswald'}>{subvariant?.value}</Text>
                                                            : ''
                                                    }
                                                </span>
                                            </FormLabel>
                                        </FormControl>
                                    ))}
                                </Flex>
                            </Flex>
                        ))}


                        <Flex justifyContent={'center'} alignItems={'center'} gap={'20px'}>
                            <Button
                                id='addtocart'
                                spinner={<Image w='20px' animation={'loaderSpin 2s linear infinite'} src={loader} alt='' />}
                                isLoading={isLoading} loadingText={t('addToCartButton')}
                                dir="rtl"
                                onClick={() => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        document.getElementById('addtocart').style.background = '#54FFA3'
                                        setButtonText(t('addToCartSuccessMessage'))
                                        setTimeout(() => { SaveDataToLocalStorage(product); document.getElementById('addtocart').style.background = '#FF5454'; setButtonText(t('addToCartButton')) }, [1000])
                                    }, [500])
                                }}
                                alignSelf={'center'}
                                minW={'150px'}
                                w='120px'
                                paddingBlock={['1rem', '1rem', '1rem', '1.2rem', '1.2rem']}
                                _hover={{ bg: '#ca4141' }}
                                color={'white'}
                                bg={'primary'}
                                fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}
                                fontWeight={'semibold'}
                                fontSize={['14px', '14px', '14px', '15px', '15px']}
                            >
                                {buttonText}
                            </Button>

                            <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                <Flex justifyContent={'center'} alignItems={'center'} gap={'20px'}>
                                    <Text cursor={'pointer'} p='5px' onClick={() => product?.quantity >= 2 ? setProduct({ ...product, quantity: product?.quantity - 1, totalPrice: ((product?.properties?.map((prop) => (prop.pricePlus)).reduce((partialSum, a) => partialSum + a, 0)) + (product?.carSize === 'small' ? smallCarPrice : bigCarPrice)) * (product?.quantity - 1) }) : null} color={'white'}>-</Text>
                                    <Text color={'white'}>{product?.quantity}</Text>
                                    <Text cursor={'pointer'} p='5px' onClick={() => setProduct({ ...product, quantity: product?.quantity + 1, totalPrice: ((product?.properties?.map((prop) => (prop.pricePlus)).reduce((partialSum, a) => partialSum + a, 0)) + (product?.carSize === 'small' ? smallCarPrice : bigCarPrice)) * (product?.quantity + 1) })} color={'white'}>+</Text>
                                </Flex>

                                <Text color={'white'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'}>{product?.totalPrice} {t('money')}</Text>
                            </Flex>
                        </Flex>
                    </Box>
                </Stack>

            </Box>

        </Flex >
    )
}
export default ProductCard;