import { Box, Flex, Image, Link, Text, useDisclosure } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import footerImage from '../../assets/images/footer-image.png';
import logo from '../../assets/vectors/intro/car-brand.svg';
import InfoCard from "../../components/info/InfoCard";
import { ApiContextData } from "../../context/ApiContext";
import LocaleContext from "../../context/LocaleContext";
const Footer = () => {
    const { locale } = useContext(LocaleContext)
    const { info, social } = useContext(ApiContextData)
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [infoType, setInfoType] = useState('');

    return (
        <>

            <Flex position={'relative'} flexDirection={['column', 'column', 'row', 'row', 'row']} gap={['20px', '20px', '0px', '0px', '0px']} maxH={'180px'} h='100%' w='100%' bg={'black'} justifyContent={'space-between'} alignItems={'center'} paddingInline={['2rem', '2rem', '4rem', '5rem', '7rem']}>
                <Box>
                    <Flex alignItems={'center'} flexDirection={['column', 'column', 'row', 'row', 'row']} gap={'20px'}>
                        <Box position={'relative'}>
                            <Image src={logo} w='100px' alt='' />
                            <Box zIndex={"2"} w='50px' h='50px' position={'absolute'} top={'50%'} left={'50%'} transform="translate(-50%,-50%)" animation={"3s ease-in light infinite"} bg='white' />
                        </Box>
                        <Flex gap={'20px'}>
                            {social?.map((item, index) => (
                                <Link target="_blank" key={index} href={item.url}><Image src={process.env.REACT_APP_BASE_URL +'/'+ item.icon} alt='' /></Link>
                            ))}
                        </Flex>
                    </Flex>
                    <Flex display={['none', 'none', 'flex', 'flex', 'flex']} alignItems={'center'} color={'white'} ml={'20px'} fontWeight={'light'} fontSize={'12px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'} gap={'60px'}>
                        <Text cursor={'pointer'} onClick={() => { setInfoType(t('footer.terms&conditions')); onOpen() }}>{t('footer.terms&conditions')}</Text>
                        <Text cursor={'pointer'} onClick={() => { setInfoType(t('footer.privacyPolicy')); onOpen() }}>{t('footer.privacyPolicy')}</Text>
                        <Text cursor={'pointer'} onClick={() => { setInfoType(t('footer.returnPolicy')); onOpen() }}>{t('footer.returnPolicy')}</Text>
                    </Flex>
                </Box>
                <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'35px'}>
                    <Image marginLeft={locale === 'en' ? ['0', '0', 'auto', 'auto', 'auto'] : ''} marginRight={locale === 'ar' ? ['0', '0', 'auto', 'auto', 'auto'] : ''} maxW='100px' src={footerImage} alt='' />
                    <Text color={'white'} mb={['20px', '20px', '0px', '0px', '0px']} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontWeight={'bold'} fontSize={['12px', '13px', '14px', '15px', '15px']}>{t('footer.goPro')} {new Date().getFullYear()} © . {t('footer.rights')}</Text>
                </Flex>
            </Flex>
            <InfoCard
                title={infoType === t('footer.terms&conditions') ? t('footer.terms&conditions') : infoType === t('footer.privacyPolicy') ? t('footer.privacyPolicy') : t('footer.returnPolicy')}
                description={infoType === t('footer.terms&conditions') ? (locale === 'en' ? info?.termsAndConditions : info?.termsAndConditionsAr) : infoType === t('footer.privacyPolicy') ? (locale === 'en' ? info?.privacyPolicy : info?.privacyPolicyAr) : (locale === 'en' ? info?.recoveryPolicy : info?.recoveryPolicyAr)}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen} />
        </>
    )
}
export default Footer;