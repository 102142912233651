import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";

import carResponsove from '../../assets/images/intro-car-responsive.png';
import car from '../../assets/images/intro-car.png';
import logo from '../../assets/images/logo.png';

import brand from '../../assets/vectors/intro/car-brand.svg';

import tabby from '../../assets/vectors/intro/tabby.svg';
import tamara from '../../assets/vectors/intro/tamara.svg';

import carDoor from '../../assets/vectors/intro/car-door.svg';
import carSeat from '../../assets/vectors/intro/car-seat.svg';
import nanoCoating from '../../assets/vectors/intro/nano-coating.svg';
import umbrella from '../../assets/vectors/intro/umbrella.svg';

import LeftLeft from '../../assets/vectors/car lights/left-left-light.svg';
import LeftRight from '../../assets/vectors/car lights/left-right-light.svg';
import RightLeft from '../../assets/vectors/car lights/right-left-light.svg';
import RightRight from '../../assets/vectors/car lights/right-right-light.svg';


import { useContext } from "react";
import { useTranslation } from "react-i18next";
import '../../assets/styles/intro.css';
import LocaleContext from "../../context/LocaleContext";
import ResponsiveContext from "../../context/ResponsiveContext";
const Intro = () => {
    const { maxwidth650 } = useContext(ResponsiveContext);
    const { locale } = useContext(LocaleContext);
    const { t } = useTranslation()
    return (
        <>
            <Flex w={'100%'} gap={['15px', '15px', '5px', '5px', '5px']} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mt={['2rem', '6rem', '6rem', '6rem', '6rem']} >

                <Flex dir="ltr" display={maxwidth650 ? 'none' : 'flex'} justifyContent={'center'} alignItems={'center'} gap={'15px'}>
                    <Box w={['0px', '60px', '70px', '80px', '100px']}><Image src={logo} w={'100%'} h={'100%'} alt="" /></Box>
                    <Text color={'primary'} fontWeight={'bold'} fontSize={['0rem', '2rem', '2.5rem', '3rem', '3.7rem']}>Go Pro</Text>
                </Flex>

                <Text color='white' mt={['50px', '50px', '0px', '0px', '0px']} fontFamily={locale === 'ar' ? 'Cairo' : 'Poppins'} fontWeight={'semibold'} fontSize={['1.5rem', '2rem', '2rem', '2rem', '2.7rem']}>{t('intro.heading')}</Text>
                {!maxwidth650 ?
                    (
                        <Box draggable={false} userSelect={'none'} position={'relative'} w={'fit-content'}>
                            <Image src={car} w={'100%'} h={'100%'} alt="" />
                            <Image opacity={'0'} animation="carLight 1s linear" src={LeftLeft} w='6%' alt='' position={'absolute'} top={'63%'} left={'24%'} transform="translate(-50%,-50%)" />
                            <Image opacity={'0'} animation="carLight 1s linear" src={LeftRight} w='5%' alt='' position={'absolute'} top={'65%'} left={'29.2%'} transform="translate(-50%,-50%)" />
                            <Image opacity={'0'} animation="carLight 1s linear" src={RightLeft} w='5%' alt='' position={'absolute'} top={'65.2%'} left={'70.7%'} transform="translate(-50%,-50%)" />
                            <Image opacity={'0'} animation="carLight 1s linear" src={RightRight} w='6%' alt='' position={'absolute'} top={'63.5%'} left={'75.6%'} transform="translate(-50%,-50%)" />
                            <Box zIndex={"2"} w='40px' h='40px' position={'absolute'} top={'70%'} left={'50%'} transform="translate(-50%,-50%)" animation={"3s ease-in light 1s infinite backwards"} bg='white' />
                            <Image src={brand} w={['50px', '50px', '50px', '70px', '80px']} h={'100%'} position={'absolute'} top={'70%'} left={'50%'} transform="translate(-50%,-50%)" alt="" />
                        </Box>
                    ) : (
                        <Box position={'relative'} w='100%'>
                            <Box display={locale === 'ar' ? 'none' : 'block'} className="ball" color={'white'} top={'15%'} left={['47%', '50%', '50%', '50%', '50%']} fontSize={'10px'}>scroll</Box>
                            <Image opacity={'0'} zIndex={'3'} animation="carLight 1s linear" src={LeftRight} w='21%' alt='' position={'absolute'} top={'70.5%'} left={'57.5%'} transform="translate(-50%,-50%)" />
                            <Image opacity={'0'} zIndex={'2'} animation="carLight 1s linear" src={RightRight} w='24%' alt='' position={'absolute'} top={'69%'} left={'75.2%'} transform="translate(-50%,-50%)" />
                            <Image src={carResponsove} w={'100%'} h={'100%'} alt="" />
                        </Box>
                    )}

                <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} mt={['0rem', '2rem', '2.2rem', '2.2rem', '2.2rem']} paddingInline={['1rem', '2rem', '2rem', '5rem', '7rem']}>
                    {!maxwidth650 &&
                        <Flex justifyContent={'center'} alignItems={'center'} gap={'15px'}>
                            <Image src={tamara} w={'40px'} h={'100%'} alt="" />
                            <Image src={tabby} w={'35px'} h={'100%'} alt="" />
                        </Flex>
                    }

                    <Flex w='100%' justifyContent={maxwidth650 ? 'space-between' : 'center'} alignItems={'center'} gap={['0px', '25px', '25px', '25px', '25px']} color='white' fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontWeight={'normal'} fontSize={['8px', '9px', '10px', '11px', '11px']}>
                        <Box>
                            <Image marginInline={'auto'} mb={'10px'} src={carSeat} w={['15px', '20px', '25px', '30px', '30px']} h={'100%'} alt='' />
                            <Text>{t('intro.upholstery')}</Text>
                        </Box>

                        <Box>
                            <Image marginInline={'auto'} mb={'10px'} src={nanoCoating} w={['15px', '20px', '25px', '30px', '30px']} h={'100%'} alt='' />
                            <Text>{t('intro.nanoCoating')}</Text>
                        </Box>

                        <Box>
                            <Image marginInline={'auto'} mb={'10px'} src={umbrella} w={['15px', '20px', '25px', '30px', '30px']} h={'100%'} alt='' />
                            <Text>{t('intro.paintProtection')}</Text>
                        </Box>

                        <Box>
                            <Image marginInline={'auto'} mb={'10px'} src={carDoor} w={['15px', '20px', '25px', '30px', '30px']} h={'100%'} alt='' />
                            <Text>{t('intro.windowFilming')}</Text>
                        </Box>
                    </Flex>

                    {!maxwidth650 && <Link href="#services"><Button _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontWeight={'bold'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={'15px'}>{t('intro.explore')}</Button></Link>}
                </Flex>
                {maxwidth650 &&
                    <Flex w='100%' mt='1.3rem' paddingInline={['1rem', '2rem', '2rem', '5rem', '7rem']} justifyContent={'space-between'} alignItems={'center'}>
                        <Flex justifyContent={'center'} alignItems={'center'} gap={'15px'}>
                            <Image src={tamara} w={'25px'} h={'100%'} alt="" />
                            <Image src={tabby} w={'20px'} h={'100%'} alt="" />
                        </Flex>

                        <Link href="#services"><Button borderRadius={'4px'} _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontWeight={'bold'} paddingInline={'0.8rem'} h='fit-content' paddingBlock={'0.5rem'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={'9px'}>{t('intro.explore')}</Button></Link>
                    </Flex>
                }
            </Flex >

        </>
    )
}
export default Intro;