import { Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveHeader from "../components/common/ResponsiveHeader";
import { ApiContextData } from "../context/ApiContext";
import LocaleContext from "../context/LocaleContext";

const TermsAndConditions = () => {
    const { info } = useContext(ApiContextData)
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()

    document.title = t('header.terms&conditions')
    
    return (
        <>
            <ResponsiveHeader backRoute={'/'} />
            <Flex mt={'100px'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'30px'} color={'white'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}>
                <Text textAlign={'center'} fontWeight={'bold'}>{t('header.terms&conditions')}</Text>
                <Text textAlign={'center'} fontWeight={'normal'}>{locale === 'en' ? info?.termsAndConditions : info?.termsAndConditionsAr}</Text>
            </Flex>
        </>
    )
}
export default TermsAndConditions