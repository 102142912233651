import { Box, Flex, Heading, Image, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import closeSquare from '../assets/vectors/close_square.svg';
import ResponsiveHeader from "../components/common/ResponsiveHeader";
import ProductCard from "../components/product/ProductCard";
import LocaleContext from "../context/LocaleContext";
import ResponsiveContext from "../context/ResponsiveContext";
import { CATEGORY } from "../services/category";
const CategoryDetails = () => {
    const { maxwidth650 } = useContext(ResponsiveContext);
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()
    const [category, setCategory] = useState();

    const { id } = useParams();
    useEffect(() => {
        CATEGORY.getCategoryById(id).then((res) => {
            setCategory(res.data);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    document.title = `Go Pro - ${category?.enName ?category?.enName:''}`

    return (
        <Box overflow={'hidden'}>
            <Flex cursor={'pointer'} position={'absolute'} top={'50px'} right={'0'} transform={'translate(-50%,-50%)'} onClick={() => navigate('/')} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                <Image w='15px' src={closeSquare} alt="" />
                <Text color={'white'} fontWeight={'medium'} fontSize={'14px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'}>{t('closeButton')}</Text>
            </Flex>
            {maxwidth650 && <ResponsiveHeader backRoute={'/'} />}
            <Flex pt={['90px', '100px', '25px', '25px', '25px']} flexDirection={'column'} gap={'20px'} alignItems={'center'}>
                <Flex justifyContent={'center'} gap={'15px'} alignItems={'center'}>
                    <Image w={['20px', '20px', '25px', '30px', '30px']} src={category?.images[1]} alt="" />
                    <Heading color={'white'} fontFamily={locale === 'en' ? 'Oswald' : 'Cairo'} fontSize={['17px', '17px', '20px', '30px', '30px']}>{locale === 'en' ? category?.enName : category?.arName}</Heading>
                </Flex>
                <Text fontSize={['10px', '13px', '15px', '20px', '20px']} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} paddingInline={'10px'} textAlign={'center'} color={'white'}>{locale === 'en' ? category?.enDescription : category?.arDescription}</Text>

                <Tabs w={'100%'} borderBottom={'1px transparent'}>
                    <TabList gap={['30px', '30px', '40px', '50px', '50px']} color={'white'} display={'flex'} justifyContent={'center'} alignSelf={'center'}>
                        {category?.products?.map((product) => (
                            <Tab key={product.id} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} paddingInline={['15px', '20px', '30px', '40px', '40px']}>{locale === 'en' ? product?.enName : product?.arName}</Tab>
                        ))}
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="white"
                        borderRadius="1px"
                    />
                    <TabPanels>
                        {category?.products?.map((product) => (
                            <TabPanel key={product.id} p='0px'>
                                <ProductCard id={product?.id} arDescription={product?.arDescription} enDescription={product?.enDescription} enName={product?.enName} arName={product?.arName} smallCarPrice={product?.smallCarPrice} bigCarPrice={product?.bigCarPrice} variants={product?.variants} image={`${process.env.REACT_APP_BASE_URL}/${product?.images ? product?.images[0] : ''}`} />
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            </Flex>

        </Box>
    )
}
export default CategoryDetails;