import {
  Box,
  Flex,
  Heading,
  Image,
  useMediaQuery
} from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import whatsapp from "../assets/vectors/media/whatsapp.svg";
import LocaleContext from "../context/LocaleContext";
import Contactus from "../layouts/landing page/Contactus";
import Footer from "../layouts/landing page/Footer";
import Header from "../layouts/landing page/Header";
import Intro from "../layouts/landing page/Intro";
import Services from "../layouts/landing page/Services";
const Home = () => {
  const [maxwidth650] = useMediaQuery("(max-width: 650px)");
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation();
  document.title = 'Go Pro'

  return (
    <>
      <Box bg={"#000000"} w="100%" h={"100vh"} position={"relative"}>
        {!maxwidth650 && (
          <Box
            cursor={"pointer"}
            zIndex={10}
            position={"fixed"}
            top={"92%"}
            right={locale === "ar" ? "" : "0"}
            left={locale === "en" ? "" : "16"}
            transform={"translate(-50%,-50%)"}
          >
            <a href="https://wa.me/966554907907" rel="noreferrer" target="_blank">
              <Flex
                maxW={"70px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Heading
                  fontSize={"14px"}
                  fontFamily={locale === "ar" ? "Cairo" : "Oswald"}
                  textAlign={"center"}
                  lineHeight={"25px"}
                  fontWeight={"normal"}
                  color={"white"}
                >
                  {t("header.needHelp")}
                </Heading>
                <Image w="25px" h="25px" src={whatsapp} alt="" />
              </Flex>
            </a>
          </Box>
        )}
        <Header />
        <Intro />
        <Services />
        <Contactus />
        <Footer />
      </Box>
    </>
  );
};
export default Home;
