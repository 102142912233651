import { Box, Button, Flex, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Menu, MenuButton, MenuDivider, MenuItemOption, MenuList, MenuOptionGroup, Text } from "@chakra-ui/react";

import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import '../assets/styles/productCard.css';
import '../assets/styles/store.css';
import closeSquare from '../assets/vectors/close_square.svg';
import filter from '../assets/vectors/filter.svg';
import loader from '../assets/vectors/page-loader.svg';
import search from '../assets/vectors/search.svg';
import cart from '../assets/vectors/shopping-cart.svg';
import store from '../assets/vectors/store.svg';
import ResponsiveHeader from "../components/common/ResponsiveHeader";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/store/Pagination";
import LocaleContext from "../context/LocaleContext";
import ResponsiveContext from "../context/ResponsiveContext";
import { PRODUCT } from "../services/product";
const Store = () => {
    const { maxwidth650 } = useContext(ResponsiveContext);
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    let timeout = 0;
    const {
        register,
        getValues,
        setValue,
        watch
    } = useForm({
        defaultValues: {
            search: "",
            query: "",
            offset: 1,
            limit: 10,
            orderBy: locale === 'en' ? "enName" : 'arName',
            orderType: "DESC",
        },
    });
    let onPageChange;
    const pageChanged = (page) => { setValue('offset', page); if (onPageChange) onPageChange(page); };

    useEffect(() => {
        setIsLoading(true)
        PRODUCT.getProductStore(getValues('query'), getValues('offset') === 1 ? 0 : (getValues('offset') - 1) * 10, getValues('limit'), getValues('orderBy'), getValues('orderType')).then((res) => {
            if (res.data.length === 0) setIsEmpty(true)
            else setIsEmpty(false)
            setData(res.data)
        }).finally(() => setIsLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('search'), watch('offset'), watch('orderBy'), watch('orderType')])

    document.title = 'Go Pro - Store'

    return (
        <>
            {maxwidth650 ? <ResponsiveHeader backRoute={'/'} />
                : (

                    <Flex position={'relative'} w='100%' h='5rem' paddingInline={['0px', '50px', '80px', '100px', '100px']} paddingBlock={'1rem'} bg='#230909C9' justifyContent={'space-between'} alignItems={'center'}>
                        <Box zIndex={'-1'} w={['0px', '500px', '500px', '600px', '750px']} borderRadius={'50%'} h='600px' filter={'blur(100px)'} opacity={'0.6'} bg='#FF5454A8' position={'absolute'} left='55%' top={'100%'} transform={'translate(-50%,-50%)'}></Box>
                        <Image w='20px' cursor={'pointer'} onClick={() => navigate('/cart')} src={cart} alt="" />

                        <Flex gap={'20px'} justifyContent={'center'} alignItems={'center'}>
                            <Image w='25%' h='25%' src={store} alt="" />
                            <Heading color={'white'} fontWeight={'medium'} letterSpacing={'0.1rem'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={'40px'}>{t('store.title')}</Heading>
                        </Flex>

                        <Flex cursor={'pointer'} onClick={() => navigate('/')} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                            <Image w='15px' src={closeSquare} alt="" />
                            <Text color={'white'} fontWeight={'medium'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={'14px'} textDecoration={'underline'}>{t('closeButton')}</Text>
                        </Flex>
                    </Flex>
                )}

            <Flex position={'relative'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} mt={maxwidth650 ? '70px' : '0px'} paddingInline={['20px', '34px', '80px', '100px', '100px']} w='100%' paddingBlock={'20px'} justifyContent={'space-between'} alignItems={'center'} color={'white'}>
                <Menu closeOnSelect={false}>
                    <MenuButton bg={"none !important"} color={"white"} _hover={{ bg: "none" }} p={"2"} as={Button}>
                        <Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
                            <Image w='17px' src={filter} alt='' />
                            {!maxwidth650 && <Text fontWeight={'medium'} fontSize={'13px'}>{t('store.filter')}</Text>}
                        </Flex>
                    </MenuButton>
                    <MenuList minWidth='240px' bg='black'>
                        <MenuOptionGroup defaultValue='asc' title={t('store.order')} type='radio' value={getValues('orderType')} onChange={(e) => setValue('orderType', e)}>
                            <MenuItemOption bg='black' _hover={{ color: "black", bg: "white" }} value='ASC'>{t('store.ascending')}</MenuItemOption>
                            <MenuItemOption bg='black' _hover={{ color: "black", bg: "white" }} value='DESC'>{t('store.descending')}</MenuItemOption>
                        </MenuOptionGroup>
                        <MenuDivider />
                        <MenuOptionGroup title={t('store.product')} type='radio' value={getValues('orderBy')} onChange={(e) => setValue('orderBy', e)}>
                            <MenuItemOption bg='black' _hover={{ color: "black", bg: "white" }} value={locale === 'en' ? 'enName' : 'arName'}>{t('store.productName')}</MenuItemOption>
                            <MenuItemOption bg='black' _hover={{ color: "black", bg: "white" }} value='smallCarPrice'>{t('store.productPrice')}</MenuItemOption>
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>


                {maxwidth650 && <> <Flex gap={'9px'} maxW={'90px'} alignItems={'center'}>
                    <Image w='32%' h='32%' src={store} alt="" />
                    <Heading color={'white'} fontWeight={'medium'} letterSpacing={'0.1rem'} fontSize={'21px'}>{t('store.title')}</Heading>
                </Flex>
                    <Image onClick={() => setIsSearchVisible(!isSearchVisible)} w={'25px'} src={search} alt='' />
                    <Box transition={'opacity 0.5s'} zIndex={isSearchVisible ? '2' : '-4'} opacity={isSearchVisible ? '1' : '0'} position={'absolute'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'}>
                        < Input
                            h='30px'
                            fontWeight={'normal'}
                            type='text'
                            {...register('query', {
                                onChange: () => {
                                    if (timeout) clearTimeout(timeout)
                                    timeout = setTimeout(() => {
                                        setValue('search', getValues('query'))
                                    }, 700)
                                }
                            })}
                            fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}
                            placeholder={t('store.search')}
                            paddingInline={'25px'}
                            paddingBlock={'0px'}
                            borderRadius={'0px'}
                            outline={'none'}
                            boxShadow={'none !important'}
                            _focusVisible={{ borderColor: 'white' }}
                            fontSize={'13px'}
                            bg='black'
                            borderLeft={'none'}
                            borderTop='none'
                            borderRight={'none'}
                            borderBottom={'1px solid white'} />
                    </Box>
                </>
                }

                {!maxwidth650 && <InputGroup className="search-products" w={'200px'}>
                    <>
                        {locale === 'en' ?
                            (
                                <InputLeftElement h={maxwidth650 ? '0px' : '30px'} maxW={'22px'} pointerEvents='none'>
                                    <Image w={'15px'} src={search} alt='' />
                                </InputLeftElement>
                            )
                            :
                            (
                                <InputRightElement h={maxwidth650 ? '0px' : '30px'} maxW={'22px'} pointerEvents='none'>
                                    <Image w={'15px'} src={search} alt='' />
                                </InputRightElement>
                            )
                        }
                    </>
                    < Input h='30px' fontWeight={'normal'} type='text' {...register('query', {
                        onChange: () => {
                            if (timeout) clearTimeout(timeout)
                            timeout = setTimeout(() => {
                                setValue('search', getValues('query'))
                            }, 700)
                        }
                    })} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} placeholder={t('store.search')} paddingInline={'25px'} paddingBlock={'0px'} borderRadius={'0px'} outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: 'white' }} fontSize={'13px'} bg='transparent' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'1px solid white'} />
                </InputGroup>}
            </Flex>


            <Flex mb={'50px'} minH={'400px'} paddingInline={['15px', '25px', '35px', '45px', '45px']} flexWrap={'wrap'} mt='30px' justifyContent={'center'} alignItems={'center'} gap={['40px', '50px', '60px', '70px', '70px']}>
                {data?.length !== 0 && !isLoading ? data?.products?.map((product) => (
                    <Flex key={product.id} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'15px'}>
                        <Flex alignItems={'center'} justifyContent={'center'} w={['90px', '100px', '130px', '160px', '160px']} h={['90px', '100px', '130px', '160px', '160px']}><Image w='100%' h={'100%'} objectFit={'cover'} fallback={<Spinner/>} src={`${process.env.REACT_APP_BASE_URL}/${product.images !== null ? product.images[0] : ''}`} alt='' /></Flex>
                        <Heading color={'white'} fontSize={'22px'} fontWeight={'light'}>{locale === 'en' ? product.enName : product.arName}</Heading>
                        <Heading fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} fontSize={'13px'} fontWeight={'bold'}>{product.smallCarPrice} {t('money')}</Heading>
                        <Button onClick={() => navigate(`/product-details/${product.id}`)} maxW='70px' h={'35px'} fontFamily={locale === 'en' ? 'Oswald' : 'Cairo'} _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontWeight={'medium'} letterSpacing={'0.06rem'} fontSize={'12px'}>{t('store.open')}</Button>
                    </Flex>
                )) : isEmpty ? <Text fontFamily={locale === 'en' ? 'Oswald' : 'Cairo'} fontSize={'20px'} color={'white'}>{t('emptyStore')}</Text>
                    : isLoading ? <Image src={loader} alt="" /> : ''}
            </Flex>

            <Pagination pageSize={getValues('limit')} totalItems={data.totalNumber} onPageChange={pageChanged} currentPage={getValues('offset')} />
        </>
    )
}
export default Store;