import { Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveHeader from "../components/common/ResponsiveHeader";
import { ApiContextData } from "../context/ApiContext";
import LocaleContext from "../context/LocaleContext";

const PrivacyPolicy = () => {
    const { info } = useContext(ApiContextData)
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()

    document.title = t('header.privacyPolicy')
    return (
        <>
            <ResponsiveHeader backRoute={'/'} />
            <Flex mt={['100px','100px','120px','120px','120px']} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'30px'} color={'white'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}>
                <Text textAlign={'center'} fontWeight={'bold'}>{t('header.privacyPolicy')}</Text>
                <Text textAlign={'center'} fontWeight={'normal'}>{locale === 'en' ? info?.privacyPolicy : info?.privacyPolicyAr}</Text>
            </Flex>
        </>
    )
}
export default PrivacyPolicy