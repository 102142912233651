import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Store from "./pages/Store";
import ResponsiveContext from "./context/ResponsiveContext";
import { Suspense, useEffect, useState } from "react";
import ProductDetails from "./pages/ProductDetails";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LocaleContext from "./context/LocaleContext";
import i18n from "./components/common/i18n";
import Loader from "./components/common/Loader";

import Cart from "./pages/Cart";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import { ApiContext } from "./context/ApiContext";
import CategoryDetails from "./pages/CategoryDetails";
import CheckoutDetails from "./pages/CheckoutDetails";
import Checkout from "./components/cart/Checkout";
function App() {
  const [maxwidth650, setMaxwidth650] = useState(
    window.matchMedia("(max-width: 650px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 650px)")
      .addEventListener("change", (e) => setMaxwidth650(e.matches));
  }, [maxwidth650]);

  const [locale, setLocale] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  return (
    <HelmetProvider>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Suspense fallback={<Loader />}>
          <Helmet
            htmlAttributes={{
              lang: locale,
              dir: locale === "en" ? "ltr" : "rtl",
            }}
          />
          <ApiContext>
            <ResponsiveContext.Provider value={{ maxwidth650 }}>
              <Router>
                <Routes>
                  <Route path="*" element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/product-details/:id" element={<ProductDetails />} />
                  <Route path="/category-details/:id" element={<CategoryDetails />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/return-policy" element={<ReturnPolicy />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/payment/success" element={<CheckoutDetails />} />
                  <Route path="/payment/cancel" element={<Cart />} />
                  <Route path="/payment/failure" element={<Cart />} />
                </Routes>
              </Router>
            </ResponsiveContext.Provider>
          </ApiContext>
        </Suspense>
      </LocaleContext.Provider>
    </HelmetProvider>
  );
}

export default App;
