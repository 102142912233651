import { createContext, useEffect, useState } from "react";
import { CATEGORY } from "../services/category";
import { INFO } from "../services/info";
import { SOCIAL } from "../services/social";

export const ApiContextData = createContext(null); // null is the default value

export const ApiContext = (props) => {
  const [categories, setCategories] = useState(null);
  const [info, setInfo] = useState({})
  const [social, setSocial] = useState([])
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    CATEGORY
      .getCategories()
      .then((response) => {
        setCategories(response.data); // update your state
      }).finally(() => setLoading(false));

    INFO.getInfo().then((res) => {
      setInfo(res.data[1]);
    });

    SOCIAL.getSocial().then((res) => {
      setSocial(res.data);
    });
  }, []);

  return (
    <ApiContextData.Provider
      value={{ categories, info, social, isLoading }} // value of your context
    >
      {props.children}
    </ApiContextData.Provider>
  );
};