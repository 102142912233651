import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { useContext } from "react";
import LocaleContext from "../../context/LocaleContext";

const InfoCard = ({ title, description, isOpen, onOpen, onClose }) => {
    const { locale } = useContext(LocaleContext)

    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} paddingInline={'30px'} minW={'500px'} minH={'400px'} maxH={'500px'} overflow={'auto'} color={'white'} bg={'#292929B2'}>
                    <ModalHeader pt={'50px'} pb={'30px'} fontSize={'18px'}>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb={'20px'}>
                        <Text>{description}</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
export default InfoCard;