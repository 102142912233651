import Axios from "axios";

class Category {
    header = { 'Accept': 'application/json', 'content-type': 'multipart/form-data' }
    getCategories = () => Axios.get(`${process.env.REACT_APP_BASE_URL}/categories`, { headers: { 'Accept': 'application/json'} })
    getCategoryById = (id) => Axios.get(`${process.env.REACT_APP_BASE_URL}/categories/${id}`, { headers: { 'Accept': 'application/json'} })

}

export const CATEGORY = new Category();
