import { Flex, Image, Stack } from "@chakra-ui/react";
import { useContext } from "react";
import loader from '../../assets/vectors/page-loader.svg';
import ServicesCard from "../../components/services/ServiceCard";
import { ApiContextData } from "../../context/ApiContext";
import LocaleContext from "../../context/LocaleContext";
const Services = () => {
  const { locale } = useContext(LocaleContext);
  const { categories, isLoading } = useContext(ApiContextData);

  return (
    <Stack
      spacing={"100px"}
      id="services"
      pt={["100px", "100px", "200px", "250px", "250px"]}
    >
      {!isLoading ? categories?.map((category, index) => (
        <ServicesCard
          key={index}
          id={category.id}
          isBlur={index + 1 === 3 ? true : false}
          borderRadius={index + 1 === 3 || index + 1 === 4 ? "50%" : ""}
          width={index + 1 === 3 || index + 1 === 4 ? "50%" : "85%"}
          isSmoke={index + 1 === 4 ? true : false}
          locale={locale}
          title={locale === "en" ? category.enName : category.arName}
          description={
            locale === "en" ? category.enDescription : category.arDescription
          }
          image={`${process.env.REACT_APP_BASE_URL}/${category.images[0]}`}
          icon={`${process.env.REACT_APP_BASE_URL}/${category.images[1]}`}
        />
      )) : <Flex justifyContent={'center'} ><Image w={'100px'} src={loader} alt='' /></Flex>}

    </Stack>
  );
};
export default Services;
