import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import language from "../../assets/vectors/language.svg";
import whatsapp from "../../assets/vectors/media/whatsapp.svg";
import menu from "../../assets/vectors/menu.svg";
import cart from "../../assets/vectors/shopping-cart.svg";
import i18n from "../../components/common/i18n";
import LocaleContext from "../../context/LocaleContext";
import ResponsiveContext from "../../context/ResponsiveContext";
const Header = () => {
  const { maxwidth650 } = useContext(ResponsiveContext);
  const { locale } = useContext(LocaleContext);

  const { t } = useTranslation()
  const changeLocale = (l) => {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();
  return (
    <>
      {!maxwidth650 ? (
        <Flex
          w={"100%"}
          p="3rem"
          h={"2rem"}
          bg={"transparent"}
          justifyContent={"space-between"}
          position={"fixed"}
          zIndex={1000}
          top={0}
        >
          <Flex w="80px"></Flex>

          <Flex
            gap={"40px"}
            color={"white"}
            fontWeight={"bold"}
            fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}
            fontSize={"15px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Image
              cursor={"pointer"}
              onClick={() => navigate("/cart")}
              src={cart}
              alt=""
              w="20px"
              h="20px"
            />
            <Link textDecoration={"none !important"} href="#services">
              <Text cursor={"pointer"}>{t("header.services")}</Text>
            </Link>
            <Text cursor={"pointer"} onClick={() => navigate("/store")}>
              {t("header.store")}
            </Text>
            <Link href="#contactus" textDecoration={"none !important"}>
              <Box
                borderRadius={"6px"}
                border={"1px solid white"}
                paddingInline={"1rem"}
                paddingBlock={"0.6rem"}
                w="fit-content"
                h={"fit-content"}
              >
                <Text>{t("header.contactUs")}</Text>
              </Box>
            </Link>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"} gap="5px">
            <Menu>
              <MenuButton
                bg={"none !important"}
                color={"white"}
                _hover={{ bg: "none" }}
                p={"2"}
                as={Button}
                leftIcon={<ChevronDownIcon />}
                fontFamily={locale==='en'?'Poppins':'Cairo'}
              >
                {t("language")}
              </MenuButton>
              <MenuList minW="60px">
                <MenuItem
                  _hover={{ color: "white", bg: "black" }}
                  onClick={
                    locale === "ar" ? () => changeLocale("en") : () => null
                  }
                >
                  EN
                </MenuItem>
                <MenuItem
                  _hover={{ color: "white", bg: "black" }}
                  onClick={
                    locale === "en" ? () => changeLocale("ar") : () => null
                  }
                >
                  AR
                </MenuItem>
              </MenuList>
            </Menu>
            <Image src={language} alt="" w="20px" h="20px" />
          </Flex>
        </Flex>
      ) : (
        <>
          <Flex
            position={"fixed"}
            w="100%"
            top={"0"}
            bg="black"
            zIndex={1000}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={["1rem", "2rem", "2rem", "2rem", "2rem"]}
          >
            <Flex justifyContent={"center"} alignItems={"center"} gap={"15px"}>
              <Box w={["25px", "25px", "50px", "50px", "50px"]}>
                <Image src={logo} w={"100%"} h={"100%"} alt="" />
              </Box>
              <Text
                color={"primary"}
                fontWeight={"bold"}
                fontSize={["0.8rem", "1rem", "1rem", "1rem", "1rem"]}
              >
                Go Pro
              </Text>
            </Flex>

            <Flex justifyContent={"center"} alignItems={"center"} gap={"15px"}>
              <Image
                src={cart}
                onClick={() => navigate("/cart")}
                alt=""
                w={["20px", "20px", "20px", "20px", "20px"]}
                h={["15px", "20px", "20px", "20px", "20px"]}
              />
              <Image
                onClick={onOpen}
                src={menu}
                alt=""
                w={["20px", "20px", "20px", "20px", "20px"]}
                h={["15px", "20px", "20px", "20px", "20px"]}
              />
            </Flex>
          </Flex>

          <Drawer
            isOpen={isOpen}
            placement={locale === "ar" ? "left" : "right"}
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent
              position={"relative"}
              w={"60% !important"}
              bg={"black"}
            >
              <DrawerCloseButton
                mt={"10px"}
                color={"white"}
                outline={"none !important"}
                boxShadow={"none"}
                _focusVisible={{ boxShadow: "none" }}
              />

              <DrawerBody>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={["start", "center", "center", "center", "center"]}
                  mt="40px"
                  gap={"40px"}
                  color={"white"}
                  fontWeight={"bold"}
                  fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'}
                  fontSize={["12px", "16px", "16px", "16px", "16px"]}
                >
                  <Link w={"100%"} onClick={onClose} href="#services">
                    <Text cursor={"pointer"} textAlign={"center"}>
                      {t("header.services")}
                    </Text>
                  </Link>
                  <Box w={"100%"}>
                    <Text
                      onClick={() => navigate("/store")}
                      textAlign={"center"}

                    >
                      {t("header.store")}
                    </Text>
                  </Box>
                  <Link
                    w={"100%"}
                    onClick={onClose}
                    href="#contactus"
                    textDecoration={"none !important"}
                  >
                    <Box
                      borderRadius={"6px"}
                      border={"1px solid white"}
                      paddingInline={"1rem"}
                      paddingBlock={"0.6rem"}
                      textAlign={"center"}
                    >
                      <Text>{t("header.contactUs")}</Text>
                    </Box>
                  </Link>
                  <Flex
                    justifyContent={"center"}
                    w={"100%"}
                    alignItems={"center"}
                    gap="5px"
                  >
                    <Menu>
                      <MenuButton
                        bg={"none !important"}
                        color={"white"}
                        _hover={{ bg: "none" }}
                        p={"2"}
                        as={Button}
                        leftIcon={<ChevronDownIcon />}
                      >
                        {t("language")}
                      </MenuButton>
                      <MenuList bg={'black'} minW="60px">
                        <MenuItem
                          bg={'black'}
                          onClick={
                            locale === "ar"
                              ? () => { onClose(); changeLocale("en") }
                              : () => null
                          }
                        >
                          EN
                        </MenuItem>
                        <MenuItem
                          bg={'black'}
                          onClick={
                            locale === "en"
                              ? () => { onClose(); changeLocale("ar") }
                              : () => null
                          }
                        >
                          AR
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <Image src={language} alt="" w="20px" h="20px" />
                  </Flex>
                  <a href="https://wa.me/966554907907" rel="noreferrer" target="_blank">
                  <Flex
                    w={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                    >
                    <Image w="20px" h="20px" src={whatsapp} alt="" />
                    <Text fontWeight={"normal"} fontSize={"14px"}>
                      {t("header.needHelp")}
                    </Text>
                  </Flex>
                    </a>
                  <Box w="100%" borderBottom={"1px solid white"}></Box>
                  <Box cursor={"pointer"} w={"100%"}>
                    <Text
                      onClick={() => navigate("/terms-and-conditions")}
                      fontSize={"12px"}
                      fontWeight={"400"}
                      textAlign={"center"}
                    >
                      {t("header.terms&conditions")}
                    </Text>
                  </Box>
                  <Box cursor={"pointer"} w={"100%"}>
                    <Text
                      onClick={() => navigate("/privacy-policy")}
                      fontSize={"12px"}
                      fontWeight={"400"}
                      textAlign={"center"}
                    >
                      {t("header.privacyPolicy")}
                    </Text>
                  </Box>
                  <Box cursor={"pointer"} w={"100%"}>
                    <Text
                      onClick={() => navigate("/return-policy")}
                      fontSize={"12px"}
                      fontWeight={"400"}
                      textAlign={"center"}
                    >
                      {t("header.returnPolicy")}
                    </Text>
                  </Box>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  );
};
export default Header;
