import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import closeSquare from '../assets/vectors/close_square.svg';
import Loader from '../components/common/Loader';
import ResponsiveHeader from "../components/common/ResponsiveHeader";
import ProductCard from "../components/product/ProductCard";
import LocaleContext from "../context/LocaleContext";
import ResponsiveContext from "../context/ResponsiveContext";
import { PRODUCT } from "../services/product";
const ProductDetails = () => {
    const { maxwidth650 } = useContext(ResponsiveContext);
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()
    const { id } = useParams()
    const [product, setProduct] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        PRODUCT.getProductDetails(id).then((res) => {
            setProduct(res.data);
        }).finally(()=>setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    document.title = `Go Pro - ${locale === 'en' ? product?.enName : product?.arName}`
    return (
        <>
            <Flex cursor={'pointer'} position={'absolute'} top={'50px'} right={'0'} transform={'translate(-50%,-50%)'} onClick={() => navigate('/store')} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                <Image w='15px' src={closeSquare} alt="" />
                <Text color={'white'} fontWeight={'medium'} fontSize={'14px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'}>{t('closeButton')}</Text>
            </Flex>
            {maxwidth650 && <ResponsiveHeader backRoute={'/store'} />}
            <Flex mb={'50px'} overflow={'hidden'} pt={['90px', '100px', '25px', '25px', '25px']} flexDirection={'column'} gap={'20px'} justifyContent={'center'} alignItems={'center'}>
                <Flex justifyContent={'center'} gap={'15px'} alignItems={'center'}>
                    <Heading color={'white'} fontSize={['17px', '17px', '20px', '30px', '30px']}>{locale === 'en' ? product?.enName : product?.arName}</Heading>
                </Flex>
                <Text fontSize={['10px', '13px', '15px', '20px', '20px']} paddingInline={'10px'} textAlign={'center'} color={'white'}>{locale === 'en' ? product?.enDescription : product?.arDescription}</Text>
                
               {isLoading?<Loader/> : <ProductCard id={product?.id} enName={product?.enName} arName={product?.arName} smallCarPrice={product?.smallCarPrice} bigCarPrice={product?.bigCarPrice} variants={product?.variants} image={`${process.env.REACT_APP_BASE_URL}/${product?.images ? product?.images[0] : ''}`} />}

            </Flex>

        </>
    )
}
export default ProductDetails;