import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import smoke from "../../assets/images/smoke.png";
import serviceIconFallback from '../../assets/vectors/link.svg';
import Spinner from "../common/Spinner";

const ServicesCard = ({
  id,
  image,
  title,
  description,
  icon,
  width = "100%",
  borderRadius = "0%",
  isBlur = false,
  isSmoke = false,
  locale,
}) => {
  const navigate = useNavigate();
  return (
    <Flex
      w={"100%"}
      gap={["20px", "20px", "0px", "0px", "0px"]}
      dir={locale === "ar" ? "ltr" : "rtl"}
      flexWrap={"wrap"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex
        position={"relative"}
        justifyContent={"center"}
        alignItems={"center"}
        w="100%"
        maxW={"100%"}
        marginInline={"auto"}
        minW={"50%"}
        flex={["100%", "100%", "60%", "60%", "60%"]}
      >
        {isBlur && (
          <Box
            bg="#FF5454"
            top={"50%"}
            left={"55%"}
            transform={"translate(-50%,-50%)"}
            zIndex={-1}
            borderRadius={"50%"}
            w="80%"
            h={"100%"}
            opacity={"0.3"}
            filter={"blur(100px)"}
            position={"absolute"}
          ></Box>
        )}
        <Flex justifyContent={'center'} w={width}>
          {" "}
          <Image
            h={"100%"}
            transform={locale === "en" ? "" : "scaleX(-1)"}
            borderRadius={borderRadius}
            src={image}
            fallback={<Spinner />}
            w={"100%"}
            alt=""
          />
        </Flex>
      </Flex>

      <Box
        position={"relative"}
        dir={locale === "ar" ? "rtl" : "ltr"}
        flex={["100%", "100%", "40%", "40%", "40%"]}
        minW={"30%"}
        w="100%"
        maxW={"100%"}
        paddingRight={
          locale === "en" ? "" : ["0rem", "0rem", "6rem", "6rem", "6rem"]
        }
        paddingLeft={
          locale === "ar" ? "" : ["0rem", "0rem", "6rem", "6rem", "6rem"]
        }
      >
        <Box
          zIndex={-1}
          transform={locale === "en" ? "" : "scaleX(-1)"}
          backgroundImage={isSmoke ? `url(${smoke})` : ""}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          position={"absolute"}
          top={"0"}
          left={"0"}
          right={"0"}
          bottom={"0"}
        ></Box>
        <Stack spacing={"5"}>
          <Flex
            alignItems={"center"}
            justifyContent={["center", "center", "start", "start", "start"]}
            gap={"15px"}
          >
            <Image fallback={<Image src={serviceIconFallback} alt="" />} src={icon} w={['20px', '30px', '50px', '50px', '50px']} h='100%' alt="" />
            <Heading
              fontFamily={locale === "en" ? "Oswald" : "Cairo"}
              fontSize={["17px", "20px", "25px", "30px", "40px"]}
              textAlign={["center", "center", "start", "start", "start"]}
              color={"white"}
              fontWeight={"medium"}
            >
              {title}
            </Heading>
          </Flex>

          <Text
            fontFamily={locale === "en" ? "Poppins" : "Cairo"}
            paddingInline={["1rem", "1rem", "0rem", "0rem", "0rem"]}
            fontSize={["15px", "17px", "20px", "20px", "25px"]}
            color={"white"}
            textAlign={["center", "center", "start", "start", "start"]}
            fontWeight={"light"}
          >
            {description}
          </Text>

          <Button
            onClick={() => navigate(`/category-details/${id}`)}
            fontFamily={locale === "en" ? "Poppins" : "Cairo"}
            w="fit-content"
            alignSelf={["center", "center", "start", "start", "start"]}
            _hover={{ bg: "#ca4141" }}
            color={"white"}
            bg={"primary"}
            fontWeight={"semibold"}
            paddingInline={["3rem", "3rem", "1.4rem", "1.4rem", "1.4rem"]}
            fontSize={"16px"}
          >
            {locale === "en" ? "Explore" : "تصفح"}
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
export default ServicesCard;
