import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocaleContext from '../../context/LocaleContext';
import { removeItemFromCart, totalPriceInCard } from '../../util/saveCartToLocalStorage';
const CartItem = ({ maxwidth650, item, setTotalPrice,setIsRemoved,isRemoved }) => {
    const { t } = useTranslation()
    const { locale } = useContext(LocaleContext)
    const [itemQuantity, setItemQuantity] = useState(item.quantity)

    let unitPrice = item.totalPrice / item.quantity
    useEffect(() => {
        let local = JSON.parse(localStorage.getItem('cart'))
        let objLocal = local?.findIndex(i => JSON.stringify({ id: item.id, carSize: item.carSize, price: item.price, properties: item.properties }) === JSON.stringify({ id: i.id, carSize: i.carSize, price: i.price, properties: i.properties }))
        if (objLocal > -1) {
            local[objLocal].quantity = itemQuantity
            local[objLocal].totalPrice = unitPrice * itemQuantity
            localStorage.setItem('cart', JSON.stringify(local));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemQuantity])

    useEffect(() => {
        setTotalPrice(totalPriceInCard())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemQuantity,item,isRemoved])
    return (

        <>
            {!maxwidth650 ? (
                <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} justifyContent={'space-between'} alignItems={'center'}>

                    <Text color={'white'} fontSize={['14px', '16px', '18px', '20px', '22px']} fontWeight={'bold'}>{locale === 'en' ? item.enName : item.arName}</Text>

                    <Flex flexDirection={'column'} justifyContent={'center'} gap={'15px'}>
                        {item?.properties?.map((property, index) => (
                            <Flex alignItems={'center'} gap={'20px'} key={index}>
                                <Text textTransform={'capitalize'} color={'white'} fontSize={'15px'} fontWeight={'medium'}>{property.name}</Text>
                                {property.type === 'image' ? <Box w='60px' h={'55px'}><Image w='100%' src={`${process.env.REACT_APP_BASE_URL}/${property.value}`} alt='' /></Box>
                                    : property.type === 'text' ? <Text color={'white'} fontSize={'15px'} fontWeight={'medium'}>{property.value}</Text>
                                        : property.type === 'color' ? <Flex borderRadius={'5px'} bg={property.value} w='30px' h='30px' />
                                            : ''
                                }
                            </Flex>
                        ))}
                    </Flex>

                    <Flex justifyContent={'center'} alignItems={'center'} gap={'10px'}>
                        <Text cursor={'pointer'} p='5px' onClick={() => itemQuantity >= 2 ? setItemQuantity(itemQuantity - 1) : null} color={'white'}>-</Text>
                        <Text color={'white'}>{itemQuantity}</Text>
                        <Text cursor={'pointer'} p='5px' onClick={() => setItemQuantity(itemQuantity + 1)} color={'white'}>+</Text>
                    </Flex>

                    <Text color={'white'} fontSize={'15px'} fontWeight={'light'}>{unitPrice * itemQuantity} {t('money')}</Text>

                    <Flex onClick={()=>{removeItemFromCart(item);setIsRemoved(true)}} cursor={'pointer'} justifyContent={'center'} alignItems={'center'} gap={'10px'}>
                        <Text color={'white'} fontFamily={'Poppins'} fontWeight={'300'} fontSize={'20px'}>x</Text>
                        <Text color={'white'} fontSize={'13px'} fontWeight={'semibold'} textDecoration={'underline'}>{t('cart.removeItem')}</Text>
                    </Flex>
                </Flex>
            ) : (
                <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} justifyContent={'start'} flexDirection={'column'} gap={'10px'}>
                    <Flex cursor={'pointer'} justifyContent={'space-between'} alignItems={'center'} gap={'10px'}>
                        <Text color={'white'} fontSize={['16px', '16px', '18px', '20px', '22px']} fontWeight={'bold'}>{locale === 'en' ? item.enName : item.arName}</Text>
                    </Flex>

                    <Flex w='100%' flexDirection={'column'} justifyContent={'center'} gap={'15px'}>
                        {item?.properties?.map((property, index) => (
                            <Flex w='100%' alignItems={'center'} justifyContent={'space-between'} key={index}>
                                <Text textTransform={'capitalize'} color={'white'} fontSize={'15px'} fontWeight={'medium'}>{property.name}</Text>
                                {property.type === 'image' ? <Image w='100%' src={`${process.env.REACT_APP_BASE_URL}/${property.value}`} alt='' />
                                    : property.type === 'text' ? <Text color={'white'} fontSize={'15px'} fontWeight={'medium'}>{property.value}</Text>
                                        : property.type === 'color' ? <Flex borderRadius={'5px'} bg={property.value} w='30px' h='30px' />
                                            : ''
                                }
                            </Flex>
                        ))}
                    </Flex>

                    <Flex justifyContent={'start'} alignItems={'center'} gap={'10px'}>
                        <Button minW={'20px'} h={'20px'} cursor={'pointer'} p='5px' onClick={() => itemQuantity >= 2 ? setItemQuantity(itemQuantity - 1) : null} color={'black'}>-</Button>
                        <Text color={'white'}>{itemQuantity}</Text>
                        <Button minW={'20px'} h={'20px'} cursor={'pointer'} p='5px' onClick={() => setItemQuantity(itemQuantity + 1)} color={'black'}>+</Button>
                    </Flex>

                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                        <Text color={'white'} fontSize={'15px'} fontWeight={'light'}>{unitPrice * itemQuantity} {t('money')}</Text>
                        <Flex cursor={'pointer'} onClick={()=>{removeItemFromCart(item);setIsRemoved(true)}} justifyContent={'center'} alignItems={'center'} gap={'10px'}>
                            <Text color={'white'} fontFamily={'Poppins'} fontWeight={'300'} fontSize={'20px'}>x</Text>
                            <Text color={'white'} fontSize={'13px'} fontWeight={'semibold'} textDecoration={'underline'}>{t('cart.removeItem')}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            )}

        </>
    )
}
export default CartItem