import { Box, Button, Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text } from "@chakra-ui/react";
import tabby from '../../assets/images/tabby-new.png'
import { InfoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import LocaleContext from "../../context/LocaleContext";
const Checkout = ({ isOpen, onClose, data, errorMessage, totalPrice }) => {
    const { t } = useTranslation()
    const { locale } = useContext(LocaleContext)
    const resultData = data?.installments?.installments ? data?.installments?.installments : Array(3).fill("");
    return (
        <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent overflowX={'hidden !important'} dir={locale === 'en' ? 'ltr' : 'rtl'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} minW={['300px','400px','600px','800px','800px']} bg={'#000000fb'} minH={'400px'} overflow={'auto'}>
                <ModalHeader fontWeight={'normal'}>{t('tabby.paymentMethodTitle')}</ModalHeader>
                <ModalBody p={['4px','20px','20px','20px','20px']}>
                    <Flex alignItems={'center'} gap={'10px'}>
                        <Box w={'50px'} h={'50px'}>
                            <Image w={'100%'} h={'100%'} objectFit={'contain'} src={tabby} alt="" />
                        </Box>
                        <Text>{t('tabby.paymentMethod')}</Text>
                        <InfoIcon />
                    </Flex>
                    {errorMessage && <Box mb={'10px'} color={'primary'}>{errorMessage}</Box>}
                    <Text mb={'10px'} color={'gray'} fontSize={'12px'}>{t('tabby.card')}</Text>

                    <Stepper overflow={'auto'} h={'70px'} index={0} marginInline={['0px','0px','10px','10px','10px']}>
                        <Step>
                            <StepIndicator border={'1px solid white !important'} position={'relative'} >
                                <Box w={'15px'} h={'17px'} borderRadius={'15px 0 0 0'} position={'absolute'} top={'0'} left={'0'} bg={'white'}></Box>

                            </StepIndicator>

                            <Box flexShrink='0'>
                                <StepTitle>{data?.installments?.downpayment ? data?.installments?.downpayment : totalPrice / 4} {t('money')}</StepTitle>
                                <StepDescription>Today</StepDescription>
                            </Box>

                            <StepSeparator />
                        </Step>
                        {resultData?.map((step, index) => (
                            <Step key={index}>
                                <StepIndicator border={'1px solid white !important'} position={'relative'} p={'2px'}>
                                    <Box w={index === 0 ? '15px' : index === 1 ? '16px' : ''} h={index === 0 ? '31px' : index === 1 ? '31px' : '15px'} borderRadius={index === 0 ? '15px 0 0 15px' : index === 1 ? '15px 0 0 15px' : ''} position={'absolute'} top={'0'} left={'0'} bg={'white'}></Box>
                                    {index === 1 && <Box w={'15px'} h={'15px'} borderRadius={'0 0 15px 0'} position={'absolute'} top={'16px'} right={'0px'} bg={'white'}></Box>}
                                    {index === 2 && <Box w={'100%'} h={'100%'} borderRadius={'100%'} bg={'white'}></Box>}
                                </StepIndicator>

                                <Box flexShrink='0'>
                                    <StepTitle>{step?.amount ? step.amount : totalPrice / 4} {t('money')}</StepTitle>
                                    <StepDescription>In {index + 1} Month</StepDescription>
                                </Box>

                                <StepSeparator />
                            </Step>
                        ))}
                    </Stepper>

                </ModalBody>

                <ModalFooter justifyContent={'end !important'} display={'flex'} gap={'15px'}>
                    <Button onClick={onClose}>{t('tabby.cancel')}</Button>

                    <Button isDisabled={errorMessage === '' ? false : true} _hover={{ bg: '#ca4141' }} color={'white'} bg={'primary'} fontWeight={'normal'} colorScheme='blue' mr={3} onClick={errorMessage === '' ? () => window.location.href = data.checkoutUrl : () => null}>
                        {t('tabby.placeOrder')}
                    </Button>

                </ModalFooter>
            </ModalContent>
        </Modal >

    )
}
export default Checkout;