export const colors = {
    primary: '#FF5454',
    danger: '#dd0909',
    success: '#09dd2c',
    warning: '#dd9d09',
    info: '#495057',
    dark: {
        100: '#292e32',
        200: '#212529',
    }
}