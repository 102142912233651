import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import closeSquare from '../../assets/vectors/close_square.svg';
import LocaleContext from "../../context/LocaleContext";
const ResponsiveHeader = ({ backRoute, closable=true }) => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext)
    const { t } = useTranslation()
    return (
        <Flex position={'fixed'} w='100%' top={'0'} bg='black' zIndex={1000} justifyContent={'space-between'} alignItems={'center'} p={['1rem', '2rem', '2rem', '2rem', '2rem']}>
            <Flex justifyContent={'center'} alignItems={'center'} gap={'15px'}>
                <Box w={['25px', '25px', '50px', '50px', '50px']}><Image src={logo} w={'100%'} h={'100%'} alt="" /></Box>
                <Text color={'primary'} fontWeight={'bold'} fontSize={['0.8rem', '1rem', '1rem', '1rem', '1rem']}>Go Pro</Text>
            </Flex>
            {closable &&
                <Flex cursor={'pointer'} onClick={() => navigate(backRoute)} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                    <Image w='15px' src={closeSquare} alt="" />
                    <Text color={'white'} fontWeight={'medium'} fontSize={'14px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'}>{t('closeButton')}</Text>
                </Flex>
            }
        </Flex>
    )
}
export default ResponsiveHeader