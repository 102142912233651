import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { CHECKOUT } from "../services/checkout"
import { useContext, useEffect, useState } from "react"
import ResponsiveContext from "../context/ResponsiveContext"
import { Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Input, Link, Stack, Text, useQuery, useToast } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import LocaleContext from '../context/LocaleContext';
import closeSquare from '../assets/vectors/close_square.svg';
import cartIcon from '../assets/vectors/shopping-cart.svg';
import ResponsiveHeader from "../components/common/ResponsiveHeader";
import check from '../assets/vectors/check.svg';
import { CheckCircleIcon } from "@chakra-ui/icons";
import CartItem from "../components/cart/CartItem";

const CheckoutDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { maxwidth650 } = useContext(ResponsiveContext);
    const { t } = useTranslation()
    const { locale } = useContext(LocaleContext)
    const [data, setData] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        CHECKOUT.getCheckoutDetails(searchParams.get('payment_id'),locale).then((res) => {
            setData(res.data.data)
        }).catch((err) => console.log(err))
        localStorage.setItem('cart', '[]')
    }, [searchParams.get('payment_id')])
    return (
        <>
            <Box h={'100%'} minH={'100vh'} mb={maxwidth650 ? '50px' : '0px'} overflow={'hidden'} position={'relative'}>
                {!maxwidth650 && <Box className='redPoint' bg='#26C281' top={'40%'} left={'20%'} transform={'translate(-50%,-50%)'} zIndex={-2} borderRadius={'50%'} w='500px' h={'500px'} opacity={'0.6'} filter={'blur(100px)'} position={'absolute'}></Box>}
                {!maxwidth650 && <Box className='whitePoint' bg='white' top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} zIndex={-1} borderRadius={'50%'} w='500px' h={'500px'} opacity={'0.4'} filter={'blur(100px)'} position={'absolute'}></Box>}

                {maxwidth650 && <ResponsiveHeader backRoute={'/'} />}

                {!maxwidth650 && <Flex w='fit-content' cursor={'pointer'} p={'20px'} onClick={() => navigate('/')} gap={'10px'} justifyContent={'start'} alignItems={'center'}>
                    <Image w='15px' src={closeSquare} alt="" />
                    <Text color={'white'} fontWeight={'medium'} fontSize={'14px'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} textDecoration={'underline'}>{t('closeButton')}</Text>
                </Flex>}

                <Flex w='100%' paddingInline={'1rem'} paddingBlock={'3rem'} mt={maxwidth650 ? '50px' : '0px'} gap={'20px'} justifyContent={'center'} alignItems={'center'}>
                    <CheckCircleIcon color={'green'} fontSize={'30px'} />
                    <Heading color={'white'} fontWeight={'medium'} letterSpacing={'0.1rem'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={['15px', '20px', '25px', '25px', '25px']}>{t('checkout.successTitle')}</Heading>
                </Flex>

                <Flex flexDirection={'column'} position={'relative'} w='100%' paddingInline={['10px', '50px', '50px', '80px', '100px']} paddingBlock={'1rem'} justifyContent={'center'} alignItems={'center'}>

                    <Flex w='100%' gap={'50px'} flexWrap={'wrap'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} justifyContent={'space-between'} alignItems={'start'}>

                        <Flex w='100%' flex={['100%', '100%', '40%', '40%', '45%']} flexDirection={'column'} justifyContent={'center'} gap={'15px'}>
                            <Heading color={'white'} textAlign={'center'} fontWeight={'medium'} letterSpacing={'0.1rem'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} fontSize={['25px', '25px', '30px', '36px', '40px']}>{t('checkout.billTitle')}</Heading>

                            {data?.order?.orderItems?.map((item, index) => (
                                <Flex w='100%' flexDirection={'column'} justifyContent={'space-between'} gap={'20px'} key={index}>
                                    <Flex alignItems={'start'} justifyContent={'start'}>
                                        <Text textTransform={'capitalize'} color={'white'} fontSize={'22px'} fontWeight={'medium'}>{JSON.parse(item.details).productName} :</Text>
                                    </Flex>
                                    <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Text>{t('checkout.billInfo.quantity')}</Text>
                                        <Text textTransform={'capitalize'} color={'white'} fontSize={'15px'} fontWeight={'medium'}>{item.quantity}</Text>
                                    </Flex>

                                    <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Text>{t('checkout.billInfo.price')}</Text>
                                        <Text textTransform={'capitalize'} color={'white'} fontSize={'15px'} fontWeight={'medium'}>{item.price} {t('money')}</Text>
                                    </Flex>

                                    {JSON.parse(item.details)?.properties?.map((prop) => (
                                        <>
                                            {prop?.image ? <Box w='60px' h={'55px'}><Image w='100%' src={`${process.env.REACT_APP_BASE_URL}/${prop?.image}`} alt='' /></Box>
                                                : prop?.text ? <Text color={'white'} fontSize={'15px'} fontWeight={'medium'}>{prop?.text}</Text>
                                                    : prop?.color ?
                                                        <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Text>{t('checkout.billInfo.color')}</Text>
                                                            <Flex borderRadius={'5px'} bg={prop?.color} w='30px' h='30px' />
                                                        </Flex>
                                                        : ''
                                            }
                                            <Box w='100%' opacity={'0.5'} borderBottom={'1px solid white'} />
                                        </>
                                    ))}
                                </Flex>
                            ))}
                            <Flex flexDirection={maxwidth650 ? 'column' : 'row'} justifyContent={'start'} alignItems={maxwidth650 ? 'start' : 'center'} gap={!maxwidth650 ? '20px' : '5px'} >
                                <Text color={'white'} fontSize={['10px', '12px', '15px', '15px', '15px']} fontWeight={'normal'}>{t('checkout.billInfo.discount')}</Text>
                                <Text color={'white'} fontSize={'17px'} fontWeight={'bold'}>{data?.order?.discountValue !== null ? data?.order?.discountValue : 0} {t('money')}</Text>
                            </Flex>

                            <Flex flexDirection={maxwidth650 ? 'column' : 'row'} justifyContent={'start'} alignItems={maxwidth650 ? 'start' : 'center'} gap={!maxwidth650 ? '20px' : '5px'} >
                                <Text color={'white'} fontSize={['10px', '12px', '15px', '15px', '15px']} fontWeight={'normal'}>{t('checkout.billInfo.total')}</Text>
                                <Text color={'white'} fontSize={'17px'} fontWeight={'bold'}>{data?.order?.amount} {t('money')}</Text>
                            </Flex>
                        </Flex>

                        <Flex flex={['100%', '100%', '40%', '40%', '45%']} w='100%' flexDirection={'column'} justifyContent={'center'} gap={'15px'}>
                            <Heading textAlign={'center'} fontSize={['25px', '25px', '30px', '36px', '40px']} fontWeight={'medium'} fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'}>{t('checkout.reservationTitle')}</Heading>
                            <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                <Text>{t('checkout.reservationInfo.name')}</Text>
                                <Text>{data?.order?.reservation?.name}</Text>
                            </Flex>

                            <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                <Text>{t('checkout.reservationInfo.phone')}</Text>
                                <Text>{data?.order?.reservation?.phone}</Text>
                            </Flex>

                            <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                <Text>{t('checkout.reservationInfo.email')}</Text>
                                <Text>{data?.order?.reservation?.email}</Text>
                            </Flex>

                            <Flex fontFamily={locale === 'en' ? 'Poppins' : 'Cairo'} color={'white'} justifyContent={'space-between'} alignItems={'center'}>
                                <Text>{t('checkout.reservationInfo.visitDay')}</Text>
                                <Text>{data?.order?.reservation?.visitDay}</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

            </Box>
        </>
    )
}
export default CheckoutDetails