import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import contactusCarResponsive from "../../assets/images/contactus-car-responsive.png";
import contactusCar from "../../assets/images/contactus-car.png";
import map from "../../assets/images/map.png";
import loaderForm from '../../assets/vectors/loader-form.svg';

import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "../../assets/styles/contactus.css";
import mapPin from "../../assets/vectors/map-pin.svg";
import phone from "../../assets/vectors/phone.svg";
import { ApiContextData } from "../../context/ApiContext";
import LocaleContext from "../../context/LocaleContext";
import ResponsiveContext from "../../context/ResponsiveContext";
import { RESERVATION } from "../../services/reservation";
import { detectMob, maxLengthCheck } from "../../util/formHandling";
const Contactus = () => {
  const { maxwidth650 } = useContext(ResponsiveContext);
  const { categories } = useContext(ApiContextData);
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation();
  const [isLabelVisible, setIsLabelVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState(t("contactus.reseveACall"));
  const toast = useToast();
  let visitDayLabel = document.getElementById('label-visit-day')

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      carType: "",
      visitDay: "",
      categoryIdsTemp: [],
      category: []
    },
  });
  const showErrorToast = (error) => {
    toast({
      title: locale === 'en' ? "something went wrong" : 'حدث خطأ ما',
      description: error,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmit = (data) => {
    setIsLoading(true)
    delete data.categoryIdsTemp
    data.phone = "+" + data.phone
    RESERVATION.reserve(data).then((res) => {
      reset({ name: "", email: "", phone: "", carType: "", visitDay: "", categoryIdsTemp: [], category: [] })
      document.getElementById('reserve').style.background = '#54FFA3';
      setButtonText(t('contactus.successText'))
    }).catch((err) => {
      showErrorToast(err.response.data.message)
    }).finally(() => {
      setIsLoading(false);
      setTimeout(() => { setButtonText(t("contactus.reseveACall")); document.getElementById('reserve').style.background = '#FF5454'; }, [3000])
    })
  }

  useEffect(() => {
    setButtonText(t('contactus.reseveACall'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])
  return (
    <Box
      overflow={locale === "en" ? "" : "hidden"}
      id="contactus"
      mt={["100px", "100px", "150px", "180px", "180px"]}
      position={"relative"}
    >
      <Box
        bg="#FF5454"
        top={"20%"}
        left={"0%"}
        transform={"translate(-50%,-50%)"}
        zIndex={1}
        borderRadius={"50%"}
        w="80%"
        h={"80%"}
        opacity={"0.4"}
        filter={"blur(80px)"}
        position={"absolute"}
      ></Box>

      <Box
        overflow={locale === "ar" ? "" : "hidden"}
        position={"relative"}
        paddingInline={["1rem", "2rem", "4rem", "5rem", "7rem"]}
        paddingBlock={"3rem"}
        backgroundImage={`url(${maxwidth650 ? contactusCarResponsive : contactusCar
          })`}
        w="100%"
        backgroundSize={"100% 100%"}
        backgroundRepeat={"no-repeat"}
        h="fit-content"
      >
        <Box
          bg="#FF5454"
          top={"90%"}
          left={"100%"}
          transform={"translate(-50%,-50%)"}
          zIndex={1}
          borderRadius={"50%"}
          w="50%"
          h={"70%"}
          opacity={"0.5"}
          filter={"blur(80px)"}
          position={"absolute"}
        ></Box>

        <Heading
          color={"white"}
          fontSize={["35px", "35px", "35px", "40px", "40px"]}
          fontFamily={locale === "en" ? "Oswald" : "Cairo"}
          fontWeight={"semibold"}
          mb={"20px"}
        >
          {t("contactus.title")}
        </Heading>
        <Flex
          position={"relative"}
          zIndex={2}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box flex={["100%", "100%", "50%", "50%", "50%"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                className="form-container"
                spacing={"10"}
                color={"white"}
                fontFamily={locale === "en" ? "Poppins" : "Cairo"}
              >
                <FormControl
                  maxW={["100%", "100%", "80%", "80%", "80%"]}
                  isInvalid={errors.name}
                >
                  <Input
                    placeholder={t("contactus.form.name")}
                    borderRadius={"0px"}
                    outline={"none"}
                    boxShadow={"none !important"}
                    _focusVisible={{ borderColor: "white" }}
                    fontSize={"15px"}
                    bg="transparent"
                    borderLeft={"none"}
                    borderTop="none"
                    borderRight={"none"}
                    borderBottom={"1px solid white"}
                    fontWeight="300"
                    type="text"
                    {...register("name", {
                      required: t('contactus.validation.name'),
                    })}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  maxW={["100%", "100%", "80%", "80%", "80%"]}
                  isInvalid={errors.email}
                >
                  <Input
                    placeholder={t("contactus.form.email")}
                    borderRadius={"0px"}
                    outline={"none"}
                    boxShadow={"none !important"}
                    _focusVisible={{ borderColor: "white" }}
                    fontSize={"15px"}
                    bg="transparent"
                    borderLeft={"none"}
                    borderTop="none"
                    borderRight={"none"}
                    borderBottom={"1px solid white"}
                    fontWeight="300"
                    type="email"
                    {...register("email", {
                      required: t('contactus.validation.email'),
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  maxW={["100%", "100%", "80%", "80%", "80%"]}
                  isInvalid={errors.phone}
                >
                  <Input
                    onInput={maxLengthCheck}
                    maxLength={15}
                    placeholder={t("contactus.form.phoneNumber")}
                    borderRadius={"0px"}
                    outline={"none"}
                    boxShadow={"none !important"}
                    _focusVisible={{ borderColor: "white" }}
                    fontSize={"15px"}
                    bg="transparent"
                    borderLeft={"none"}
                    borderTop="none"
                    borderRight={"none"}
                    borderBottom={"1px solid white"}
                    fontWeight="300"
                    type="number"
                    {...register("phone", {
                      required: t('contactus.validation.phoneNumber'),
                    })}
                  />
                  <FormErrorMessage>
                    {errors.phone &&
                      errors.phone.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  maxW={["100%", "100%", "80%", "80%", "80%"]}
                  isInvalid={errors.carType}
                >
                  <Input
                    placeholder={t("contactus.form.carType&Model")}
                    borderRadius={"0px"}
                    outline={"none"}
                    boxShadow={"none !important"}
                    _focusVisible={{ borderColor: "white" }}
                    fontSize={"15px"}
                    bg="transparent"
                    borderLeft={"none"}
                    borderTop="none"
                    borderRight={"none"}
                    borderBottom={"1px solid white"}
                    fontWeight="300"
                    type="text"
                    {...register("carType", {
                      required: t('contactus.validation.carType&Model'),
                    })}
                  />
                  <FormErrorMessage>
                    {errors.carType && errors.carType.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  position={'relative'}
                  maxW={["100%", "100%", "80%", "80%", "80%"]}
                  isInvalid={errors.visitDay}
                >
                  <FormLabel id='label-visit-day' color={'#ADADAD'} fontWeight={'300'} position={'absolute'} bg={'transparent'} top={'6px'} left={!detectMob() ? (locale === 'en' ? '15px' : '') : '15px'} right={!detectMob() ? (locale === 'ar' ? '50px' : '') : '15px'}>
                    {t("contactus.form.bestVisitDay")}
                  </FormLabel>
                  <Input

                    onFocus={visitDayLabel ? () => { visitDayLabel.style.display = 'none'; setIsLabelVisible(false) } : () => { visitDayLabel.style.display = 'block'; setIsLabelVisible(true) }}
                    id=''
                    borderRadius={"0px"}
                    outline={"none"}
                    boxShadow={"none !important"}
                    _focusVisible={{ borderColor: "white" }}
                    fontSize={"15px"}
                    bg="transparent"
                    borderLeft={"none"}
                    borderTop="none"
                    borderRight={"none"}
                    borderBottom={"1px solid white"}
                    fontWeight="300"
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    color={isLabelVisible ? 'transparent' : 'white'}
                    {...register("visitDay", {
                      required: t('contactus.validation.bestVisitDay'),
                    })}
                  />
                  <FormErrorMessage>
                    {errors.visitDay && errors.visitDay.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <Grid templateColumns="repeat(2, 1fr)" maxW="400px" >
                  {categories?.map((cate) => (
                    <GridItem key={cate.id} >
                      <FormControl isInvalid={errors.category} >
                        <FormLabel
                          w={"100%"}
                          h={"100%"}
                          m={"0"}
                          className="custom-checkbox"
                        >
                          <Input
                            name={cate.name}
                            value={cate.id}
                            {...register("categoryIdsTemp", {
                              required: "please select a service",
                              onChange: (e) => { setValue('category', getValues("categoryIdsTemp").map((item) => parseInt(item))) },
                            })}
                            type="checkbox"
                          />
                          <span style={{ fontSize: maxwidth650 ? '12px' : '16px', textAlign: 'center', fontWeight: "400" }}>
                            {locale === 'en' ? cate.enName : cate.arName}
                          </span>
                        </FormLabel>
                        <FormErrorMessage>
                          {errors.category && errors.category.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  ))}
                </Grid>
                <Button
                  id='reserve'
                  dir="rtl"
                  isLoading={isLoading}
                  loadingText={t("contactus.reseveACall")}
                  spinner={<Image w='20px' animation={'loaderSpin 2s linear infinite'} src={loaderForm} alt='' />}
                  maxW="220px"
                  mb="20px"
                  paddingBlock={[
                    "1rem",
                    "1.2rem",
                    "1.5rem",
                    "1.8rem",
                    "1.8rem",
                  ]}
                  _hover={{ bg: "#ca4141" }}
                  color={"white"}
                  bg={"primary"}
                  fontWeight={"bold"}
                  fontFamily={locale === "en" ? "Poppins" : "Cairo"}
                  fontSize={["15px", "15px", "17px", "20px", "20px"]}
                  type="submit"
                >
                  {buttonText}
                </Button>
              </Stack>
            </form>
          </Box>
          <Flex
            flexDirection={["row", "row", "column", "column", "column"]}
            flex={["100%", "100%", "50%", "50%", "50%"]}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"40px"}
          >
            <Image
              src={map}
              minW={"150px"}
              maxW={["20%", "30%", "100%", "100%", "100%"]}
              alt=""
            />
            <Box>
              <Flex mb={"15px"} alignItems={"center"} gap={"10px"}>
                <Image src={mapPin} alt="" />
                <Heading
                  color={"white"}
                  fontWeight={["normal", "medium", "semibold", "bold", "bold"]}
                  fontFamily={locale === "en" ? "Oswald" : "Cairo"}
                  fontSize={"14px"}
                >
                  {t("contactus.location")}
                </Heading>
              </Flex>

              <Flex alignItems={"center"} gap={"10px"}>
                <Image src={phone} alt="" />
                <Heading
                  dir="ltr"
                  color={"white"}
                  fontWeight={["normal", "medium", "semibold", "bold", "bold"]}
                  fontFamily={locale === "en" ? "Oswald" : "Cairo"}
                  fontSize={"14px"}
                >
                  {t("contactus.mobile")}
                </Heading>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
export default Contactus;
