import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Flex, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import {
    DOTS,
    usePagination
} from "../../hooks/usePagination";



function Pagination({ totalItems, pageSize, currentPage, siblingCount = 1, onPageChange, }) {

    const paginationRange = usePagination({
        currentPage: currentPage,
        pageSize: pageSize,
        totalItems: totalItems,
        siblingCount: siblingCount,
    });
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange? paginationRange[paginationRange?.length - 1]:'';
    return (
        <Flex mt='50px' mb={'50px'} justifyContent={'center'} align="center" gap="10px" dir="ltr">
            {/* Left navigation arrow */}
                <IconButton
                    onClick={onPrevious}
                    isDisabled={currentPage === 1}
                    bg={'transparent'}
                    color={'white'}
                    aria-label="الصفحة السابقة"
                    variant={'unstyled'}
                    icon={<ChevronLeftIcon/>}
                ></IconButton>
            {paginationRange?.map((pageNumber, i) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <i key={i} className="bi bi-three-dots" ></i>;
                }

                // Render our Page Pills
                return (
                    <Button
                        minW='34px'
                        w='34px'
                        h='2rem'
                        key={i}
                        fontWeight={500}
                        color={pageNumber === currentPage ? "white" : "primary"}
                        bg={pageNumber===currentPage?'primary':''}
                        colorScheme={'primary'}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        <Text h='18px'>{pageNumber}</Text>
                    </Button>
                );
            })}
            {/*  Right Navigation arrow */}
                <IconButton
                    onClick={onNext}
                    isDisabled={currentPage === lastPage}
                    bg={'transparent'}
                    color={'white'}
                    aria-label="الصفحة التالية"
                    variant={'unstyled'}
                    icon={<ChevronRightIcon/>}
                ></IconButton>
        </Flex>
    );
}

export default Pagination;
