import { Box, Flex, Image } from '@chakra-ui/react';
import '../../assets/styles/loader.css';
import brand from '../../assets/vectors/intro/car-brand.svg';
function Loader() {
    return (
        <Flex w='100%' h='100%' justifyContent={'center'} alignItems={'center'} position={'fixed'} top='0' left='0' right='0' bottom={'0'} background={'black'} overflow={'hidden'}>
            <Box zIndex={"2"} w={['75px', '75px', '100px', '100px', '100px']} filter={'blur(20px)'} h={['75px', '75px', '100px', '100px', '100px']} position={'absolute'} top={'50%'} left={'50%'} transform="translate(-50%,-50%)" animation={"3s ease-in loaderLight infinite backwards"} bg='white' />
            <Image src={brand} w={['100px', '100px', '200px', '200px', '200px']} h={'100%'} position={'absolute'} top={'50%'} left={'50%'} transform="translate(-50%,-50%)" alt="" />
        </Flex>
    )
}
export default Loader;